import React, {PropsWithChildren, useEffect, useState} from 'react';
import './index.scss';
import {
    IonIcon,
    IonItem,
    IonList,
    IonToast,
    IonAlert,
    IonInfiniteScroll, IonInfiniteScrollContent, IonRefresher, IonRefresherContent
} from '@ionic/react';
import Collection from "../../../models/user/collection";
import {UserCollectionsContextState} from "../../../contexts/UserCollectionsContext";
import CollectionManagementRequests from "../../../services/requests/CollectionManagementRequests";
import {
    CollectionItemsContext,
    CollectionItemsContextProvider,
    CollectionItemsContextState
} from "../../../contexts/CollectionItemsContext";
import AlbumPostImage from "../AlbumPostImage";
import {remove, removeCircle} from "ionicons/icons";
import {useLocation} from "react-router";
import {CACHE_REFRESH_WAIT_TIME, PostResponseContextProvider} from "../../../contexts/PostResponseContet";

interface AlbumListSharedProps {
    onCollectionClicked?: (collection: Collection) => void
    inDeleteMode: boolean
}

interface AlbumSquareProps extends AlbumListSharedProps{
    collection: Collection
    onDeleteClicked: () => void,
}

const AlbumSquare: React.FC<AlbumSquareProps> = ({collection, inDeleteMode, onDeleteClicked, onCollectionClicked}) => {

    const collectionLink =
        !onCollectionClicked ? '/home/dashboard/albums/' + collection.id : undefined
    const collectionClicked = () => onCollectionClicked ? onCollectionClicked(collection) : undefined

    const deleteClicked = (event: any) => {
        event.preventDefault();
        onDeleteClicked()
    }

    return (
        <IonItem
            className={'album-list-item '}
            routerLink={collectionLink}
            lines={'none'}
            detail={false}
            onClick={() => collectionClicked()}
        >
            <div className={'album-item-content'}>
                {inDeleteMode &&
                    <React.Fragment>
                        <IonIcon
                            icon={remove}
                            color={'light'}
                        />
                        <IonIcon
                            icon={removeCircle}
                            color={'danger'}
                            onClick={deleteClicked}
                        />
                    </React.Fragment>
                }
                <AlbumPostImage
                    imageUrl={collection.last_post_thumbnail_url}
                />
                <p>{collection.name}</p>
                <small>{collection.collection_items_count ?? '0'} Saved</small>
            </div>
        </IonItem>
    )
}

interface AlbumItemProps extends AlbumListSharedProps {
    collection: Collection
    onDeleteCollection: (collection: Collection) => void
}

const AlbumItem: React.FC<AlbumItemProps> = ({collection, onDeleteCollection, ...rest}) => {
    const [confirmDeletionShowing, setConfirmDeletionShowing] = useState(false);

    return (
        <React.Fragment>
            <AlbumSquare
                collection={collection}
                onDeleteClicked={() => setConfirmDeletionShowing(true)}
                {...rest}
            />
            <IonAlert
                isOpen={confirmDeletionShowing}
                onDidDismiss={() => setConfirmDeletionShowing(false)}
                header={"Are you sure?"}
                message={'"' + collection.name + '" will be permanently deleted.'}
                buttons={[
                    {
                        text: 'Cancel',
                        role: 'cancel',
                    },
                    {
                        text: 'Delete',
                        role: 'delete',
                        handler: () => onDeleteCollection(collection)
                    },
                ]}
            />
        </React.Fragment>
    )
}


interface AlbumListProps extends AlbumListSharedProps {
    collectionContext: UserCollectionsContextState
}

const AlbumList: React.FC<AlbumListProps> = ({collectionContext, ...rest}) => {
    const [refreshPostResponses, setRefreshPostResponses] = useState(false)
    const [needsReset, setNeedsReset] = useState(false);
    const [isDeleteErrorShowing, setIsDeleteErrorShowing] = useState(false);
    const deleteCollection = (removeCollection: (model: Collection) => void, collection: Collection) => {
        CollectionManagementRequests.deleteCollection(collection).catch(error => {
            setIsDeleteErrorShowing(true)
        }).finally(() =>  {
            setTimeout(() => setRefreshPostResponses(true), CACHE_REFRESH_WAIT_TIME)
        });
        removeCollection(collection);
    }

    const collections = collectionContext.loadedData
        .sort((a: Collection, b: Collection) => Date.parse(b.created_at!)- Date.parse(a.created_at!))

    const location = useLocation()

    useEffect(() => {
        if (location.pathname.endsWith("albums")) {
            if (needsReset) {
                collectionContext.refreshData(true);
                setNeedsReset(false)
            }
        } else {
            setNeedsReset(true)
        }
    }, [location]);

    return (
        <PostResponseContextProvider
            clearCache={refreshPostResponses}
            onCacheCleared={() => setRefreshPostResponses(false)}
        >
            <IonList className={'album-list'}>
                {collections.map(collection =>
                    <AlbumItem
                        key={collection.id}
                        collection={collection}
                        onDeleteCollection={collection => deleteCollection(collectionContext.removeModel, collection)}
                        {...rest}
                    />
                )}
            </IonList>
            <IonInfiniteScroll
                onIonInfinite={(event: any) => {
                    collectionContext.loadNext().then(() => {
                        event.target.complete();
                    })
                }}
                disabled={!collectionContext.hasAnotherPage}
            >
                <IonInfiniteScrollContent title={'Loading more'}/>
            </IonInfiniteScroll>
            <IonToast
                isOpen={isDeleteErrorShowing}
                onDidDismiss={() => setIsDeleteErrorShowing(false)}
                duration={2000}
                message={'Error deleting collection'}
            />
        </PostResponseContextProvider>
    )
}


export default AlbumList
