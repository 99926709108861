import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, FilterProps, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import PostResponse from "../models/post/post-response";

/**
 * The state interface for our state
 */
export interface PostResponsesContextState extends BasePaginatedContextState<PostResponse> {}

const defaultContext = {
    ...defaultBaseContext(),
    expands: [
        'user',
    ],
    order: {
        "updated_at": 'desc',
    }
} as PostResponsesContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
const cachedStates: { [key: string]: PostResponsesContextState; } = {};

/**
 * The actual context component
 */
export const PostResponsesContext = React.createContext<PostResponsesContextState>(defaultContext);

export type AvailableInteractions = "liked" | "saved" | "shared" | "clicked";

export interface PostResponsesContextProviderProps extends BasePaginatedContextProviderProps{
    postId: number,
    interaction?: AvailableInteractions,
}

// Shares our state setter in order to run updates across all consumers
let setters: any = {};

export const PostResponsesContextProvider: React.FC<PostResponsesContextProviderProps> = (({postId, interaction, ...props}) => {

    const dataKey = postId + "-" + interaction;
    if (!cachedStates[dataKey]) {
        let filter = {} as FilterProps;
        if (interaction) {
            filter[interaction] = 1;
        }

        cachedStates[dataKey] = {
            ...defaultBaseContext(),
            filter: filter,
            expands: [
                'user',
            ],
            order: {
                "updated_at": 'desc',
            }
        };
    }

    const [postResponsesState, setPostResponsesState] = useState(cachedStates[dataKey]);

    useEffect(() => {
        setters[dataKey] = setPostResponsesState

        prepareContextState(setPostResponsesState, cachedStates[dataKey],'/posts/' + postId + '/responses')
    }, [dataKey]);

    useEffect(() => {
        cachedStates[dataKey] = postResponsesState;
        Object.keys(setters).forEach((key: string) => {
            if (parseInt(key) === postId) {
                setters[key](postResponsesState)
            }
        })
    }, [postResponsesState])
    return (

        <PostResponsesContext.Provider value={createCallbacks(setPostResponsesState, postResponsesState, '/posts/' + postId + '/responses')}>
            {props.children}
        </PostResponsesContext.Provider>
    )
});
