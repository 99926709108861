import React, {useEffect, useState} from 'react'
import {IonContent, IonPage, IonToast, useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';
import './index.scss';
import {PostContext, PostContextProvider} from '../../../contexts/PostContext';
import Post from '../../../models/post/post';
import OrganizationHeader, {
	OrganizationHeaderContext, OrganizationHeaderProps
} from '../../../components/OrganizationManagement/OrganizationHeader';
import {useHistory} from 'react-router-dom';
import {connect} from '../../../data/connect';
import { setManagingBusinessId } from '../../../data/persistent/persistent.actions';
import {useLocation, useParams} from 'react-router';
import InteractivePost from '../../../components/Posts/InteractivePost';
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";

interface PostPageContentProps extends StateProps, DispatchProps {
	post: Post,
	url: string
	setPost: (post: Post) => void,
	inFocus: boolean,
}

const PostPageContent: React.FC<PostPageContentProps> = ({post, setPost, inFocus, url, setManagingBusinessId, managingBusinessId}) => {

	const navigate = useHistory();
	const [postRemovedToastShowing, setPostRemovedToastShowing] = useState(false);
	const viewLikes = () => {
		navigate.push(`${url}/likes`)
	}

	useEffect(() => {
		if (!managingBusinessId) {
			setManagingBusinessId(post.locations![0].business_id ?? undefined);
		}
	}, [])

	const postRemoved = () => {
		setPostRemovedToastShowing(true);
		setTimeout(navigate.goBack, 1000);
	}

	return ( managingBusinessId ?
		<MeContextProvider>
			<MeContext.Consumer>
				{meContext =>
					<IonContent className={'post-details-content-wrapper ion-no-padding'}>
						<IonToast
							isOpen={postRemovedToastShowing}
							onDidDismiss={() => setPostRemovedToastShowing(false)}
							message={'This post has been removed. It will no longer be shown to anyone besides the person who published it.'}
							duration={2000}
						/>
						<InteractivePost
							post={post}
							loggedInUser={meContext.me}
							location={post.locations?.find(() => true)}
							onPostRemoved={postRemoved}
							onPostMetaChanged={setPost}
							locationPageUrlRoot={'/organization'}
							onLikeClicked={viewLikes}
							inFocus={inFocus}
							hasViewed
						/>
					</IonContent>
				}
			</MeContext.Consumer>
		</MeContextProvider> : <span/>
	)
}

interface PostPageLoaderProps extends PostPageProps {
	setHeaderProps: (props: OrganizationHeaderProps) => void,
	inFocus: boolean,
}

const PostPageLoader: React.FC<PostPageLoaderProps> = ({managingBusinessId, setHeaderProps, ...rest}) => {

	const {postId} = useParams<RouteParams>()
	const location = useLocation()

	useEffect(() => {
		setHeaderProps({
			children: 'Post',
			hideTrialInformation: !managingBusinessId
		});
	}, [managingBusinessId])

	return (
		<PostContextProvider postId={parseInt(postId!)}>
			<PostContext.Consumer>
				{postContext =>
					<PostPageContent
						key={postContext.post.id}
						post={postContext.post}
						setPost={postContext.setPost}
						url={location.pathname}
						managingBusinessId={managingBusinessId}
						{...rest}
					/>
				}
			</PostContext.Consumer>
		</PostContextProvider>
	)
}

type RouteParams = {
	postId: string,
}

interface StateProps {
	managingBusinessId?: number
}

interface DispatchProps {
	setManagingBusinessId: typeof setManagingBusinessId,
}

interface PostPageProps extends StateProps, DispatchProps {}

const PostPage: React.FC<PostPageProps> = (props) => {

	const [inFocus, setInFocus] = useState(false);

	useIonViewWillEnter(() => {
		setInFocus(true);
	})
	useIonViewWillLeave(() => {
		setInFocus(false);
	})

	return (
		<IonPage className={'post-page'}>
			<IonContent scrollY={false}>
				<OrganizationHeaderContext.Consumer>
					{organizationHeaderContext =>
						<PostPageLoader
							inFocus={inFocus}
							setHeaderProps={organizationHeaderContext.setSharedProps}
							{...props}
						/>
					}
				</OrganizationHeaderContext.Consumer>
			</IonContent>
		</IonPage>
	)

}

export default connect<{}, StateProps, DispatchProps >({
	mapStateToProps: (state) => ({
		managingBusinessId: state.persistent.managingBusinessId
	}),
	mapDispatchToProps: ({
		setManagingBusinessId,
	}),
	component: PostPage
});
