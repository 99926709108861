import {BasePaginatedContextState, defaultBaseContext} from "../BasePaginatedContext";
import Post from "../../models/post/post";
import {FeedSettings} from "../../data/persistent/persistent.state";

export interface BaseFeedContextState extends BasePaginatedContextState<Post> {}

export const defaultFeedContext = () => ({
    ...defaultBaseContext(),
    limit: 6,
    expands: [
        'locations',
        'locations.business',
        'postLocations',
        'publisher',
    ],
} as BaseFeedContextState);

export interface BaseFeedStateProps {
    feedSettings: FeedSettings,
}
