import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonButtons,
    IonContent, IonFooter,
    IonHeader, IonIcon, IonInput,
    IonModal,
    IonTitle, IonToast,
    IonToolbar
} from '@ionic/react';
import {close} from 'ionicons/icons';
import './index.scss'
import MeContextProvider, {MeContext} from "../../../contexts/MeContext";
import {
    UserCollectionsContextProvider,
    UserCollectionsContext,
    UserCollectionsContextState
} from "../../../contexts/UserCollectionsContext";
import * as Yup from "yup";
import {useFormik} from "formik";
import {RequestError} from "../../../models/request-error";
import ServerAlert from "../../ServerAlert";
import InputWrapper from "../../InputWrapper";
import Collection, {collectionDefaultData} from "../../../models/user/collection";
import CollectionManagementRequests from "../../../services/requests/CollectionManagementRequests";
import AlbumList from "../AlbumList";
import Post from "../../../models/post/post";
import {CollectionItemsContextState} from "../../../contexts/CollectionItemsContext";

interface CollectionCreatorData {
    name: string,
}
interface AlbumViewsProps {
    userCollectionsContext: UserCollectionsContextState;
    userId: number;
}

interface CreateAlbumViewProps extends AlbumViewsProps {
    showAddAlbumOnly: boolean;
    setRequestError: (error: RequestError|undefined) => void
    onCollectionCreated: (collection: Collection) => void;
    postThumbnailUrl?: string
}

const CreateAlbumView: React.FC<CreateAlbumViewProps> = ({userId, userCollectionsContext, onCollectionCreated, showAddAlbumOnly, postThumbnailUrl, setRequestError}) => {
    const submitForm = (userInputData: CollectionCreatorData) => {
        const collectionData : any = {
            ...collectionDefaultData,
            ...userInputData,
        };

        CollectionManagementRequests.createCollection(userId, collectionData).then((collection) => {

            if (collection.collection_items_count !== 1) {
                collection.collection_items_count = 0
            }

            collection.last_post_thumbnail_url = postThumbnailUrl;

            userCollectionsContext.addModel(collection)
            onCollectionCreated(collection)
        }).catch((error: RequestError) => {
            setRequestError(error)
        })
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required().max(50),
    });

    const getCurrentError = () => {
        if (form.errors.name && form.touched.name) {
            return form.errors.name;
        }

        return "";
    }

    const form = useFormik({
        initialValues: {name: ''},
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => submitForm({
            name: values.name,
        })
    });

    const clearField = () => {
        form.setFieldValue('name', '')
    }

    return (
        <React.Fragment>
            <IonContent>
                <InputWrapper label={""} rounded={true} color={'dark'} subtext={form.values.name.length + '/50 characters'}>
                    <IonInput
                        autocapitalize={'word'}
                        type='text'
                        name='name'
                        placeholder={'Name'}
                        value={form.values.name}
                        onIonChange={form.handleChange}
                    />
                </InputWrapper>
                <p className={'error'}>
                    {getCurrentError()}
                </p>
            </IonContent>
            <IonFooter>
                <IonButton color={'dark'} onClick={() => form.handleSubmit()}>
                    Create
                </IonButton>
                {!showAddAlbumOnly ? (<IonButton
                    fill={'clear'}
                    color={'dark'}
                    onClick={clearField}
                    className={'clear'}
                >
                    Clear
                </IonButton>) : null}
            </IonFooter>
        </React.Fragment>
    )
}

interface SelectAlbumViewProps extends AlbumViewsProps {
    postId: number
    toggleCreateAlbum: () => void
    onCollectionSelected: (collection: Collection) => void;
}
const SelectAlbumView: React.FC<SelectAlbumViewProps> = ({postId, toggleCreateAlbum, userCollectionsContext, onCollectionSelected}) => {

    return (
        <React.Fragment>
            <IonContent>
                {userCollectionsContext.loadedData.length ? (
                    <AlbumList
                        collectionContext={userCollectionsContext}
                        onCollectionClicked={onCollectionSelected}
                        inDeleteMode={false}
                    />
                ) : (
                    <h5>You don't have any albums yet! Click <strong>Create album</strong> to get started.</h5>
                )}
            </IonContent>
            <IonFooter>
                <IonButton color={'dark'} onClick={toggleCreateAlbum} className={'add-album'}>
                    Create album
                </IonButton>
            </IonFooter>
        </React.Fragment>
    )
}

interface AlbumModalContentProps {
    userId: number
    showAddAlbum: boolean
    showAddAlbumOnly: boolean
    toggleCreateAlbum: () => void
    userCollectionsContext: UserCollectionsContextState
    post?: Post
    onComplete: (collection: Collection) => void
    postSaved?: (saveConfirmed: boolean) => void
}

const AlbumModalContent: React.FC<AlbumModalContentProps> = ({userId, postSaved, showAddAlbum, toggleCreateAlbum, showAddAlbumOnly, userCollectionsContext, post, onComplete}) => {
    const [requestError, setRequestError] = useState<RequestError|undefined>(undefined);

    const onCollectionCreated = (collection: Collection) => {
        toggleCreateAlbum()
        onCollectionSelected(collection)
    }

    const onCollectionSelected = (collection: Collection) => {

        const collectionItemData: any = {
            item_id: post!.id!,
            item_type: 'post',
            collection_id: collection.id!,
            order: collection.collection_items_count ?? 0,
        }

        CollectionManagementRequests.createCollectionItem(collection, collectionItemData).then(() => {

            if (userCollectionsContext) {
                userCollectionsContext.addModel({
                    ...collection,
                    collection_items_count: collection.collection_items_count + 1,
                })
            }

            if (postSaved) {
                postSaved(true)
            }
            onComplete(collection)
        }).catch((error: RequestError) => {
            setRequestError(error)
        })

    }

    return (
        <React.Fragment>
            {!showAddAlbum && post ? (
                <SelectAlbumView
                    postId={post.id!}
                    toggleCreateAlbum={toggleCreateAlbum}
                    userCollectionsContext={userCollectionsContext}
                    userId={userId}
                    onCollectionSelected={onCollectionSelected}
                />
            ) : (
                <CreateAlbumView
                    userCollectionsContext={userCollectionsContext}
                    userId={userId}
                    postThumbnailUrl={post?.main_image_url}
                    onCollectionCreated={onCollectionCreated}
                    showAddAlbumOnly={showAddAlbumOnly}
                    setRequestError={setRequestError}
                />
            )}
            {requestError &&
                <ServerAlert
                    requestError={requestError}
                    onCloseAlert={() => setRequestError(undefined)}
                />
            }
        </React.Fragment>
    )
}

interface AlbumModalProps extends React.ComponentProps<typeof IonModal> {
    showAddAlbumOnly: boolean
    post?: Post
    onPostSave?: (saveConfirmed: boolean) => void
}

const AlbumModal: React.FC<AlbumModalProps> = ({onDidDismiss, onPostSave, showAddAlbumOnly, post, ...rest}) => {
    const [showAddAlbum, setShowAddAlbum] = useState(showAddAlbumOnly)
    const [successToastMessage, setSuccessToastMessage] = useState("")

    const back = () => {
        if (showAddAlbum) {
            setShowAddAlbum(false)
        }
        else if (onDidDismiss) {
            (onDidDismiss as any)()
        }
    };
    const onToggleCreateAlbum = () => setShowAddAlbum(!showAddAlbum)

    const onComplete = (collection: Collection) => {
        setSuccessToastMessage("Added to your album " + collection.name + ".");
        if (onDidDismiss) {
            (onDidDismiss as any)()
        }
    }

    return (
        <React.Fragment>
            <IonModal
                initialBreakpoint={1}
                breakpoints={[1]}
                onDidDismiss={onDidDismiss}
                className={'save-to-album-modal'}
                {...rest}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot={'start'}>
                            <IonButton
                                fill={'clear'}
                                color={'dark'}
                                onClick={back}
                            >
                                <IonIcon icon={close}/>
                            </IonButton>
                        </IonButtons>
                        <IonTitle>{showAddAlbum ? "Create album" : "Add to album"}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <MeContextProvider>
                    <MeContext.Consumer>
                        {meContext =>
                            <UserCollectionsContextProvider userId={meContext.me.id!}>
                                <UserCollectionsContext.Consumer>
                                    {userCollectionsContext => (
                                        <AlbumModalContent
                                            userId={meContext.me.id!}
                                            userCollectionsContext={userCollectionsContext}
                                            showAddAlbum={showAddAlbum}
                                            showAddAlbumOnly={showAddAlbumOnly}
                                            toggleCreateAlbum={onToggleCreateAlbum}
                                            onComplete={onComplete}
                                            post={post}
                                            postSaved={onPostSave}
                                        />
                                    )}
                                </UserCollectionsContext.Consumer>
                            </UserCollectionsContextProvider>
                        }
                    </MeContext.Consumer>
                </MeContextProvider>
            </IonModal>
            <IonToast
                isOpen={successToastMessage.length > 0}
                message={successToastMessage}
                onDidDismiss={() => setSuccessToastMessage("")}
                duration={2000}
            />
        </React.Fragment>
    )
}

export default AlbumModal
