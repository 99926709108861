import React, {useEffect, useState} from 'react';
import PostResponse, {defaultPostResponse} from "../models/post/post-response";
import UserPostRequests from "../services/requests/UserPostRequests";
import postComments from "../components/Posts/PostComments";

type postId = number

interface LoadedPostResponses {
    [key: postId] : PostResponse
}
interface PostResponseSubscribers {
    [key: string] : (postResponse?: PostResponse) => void
}

let cachedPostResponses = {} as LoadedPostResponses;

/**
 * The structure of the consumer
 */
export interface PostResponseContextConsumerState {
    setPostResponse: (postResponse?: PostResponse) => void,
    postResponse?: PostResponse,
}

let defaultContext: PostResponseContextConsumerState = {
    setPostResponse: (postResponse?: PostResponse) => {}
};

let subscriptions = {} as PostResponseSubscribers

export const CACHE_REFRESH_WAIT_TIME = 1000;

export const PostResponseContext = React.createContext<PostResponseContextConsumerState>(defaultContext);

export interface PostContextProviderProps {
    userId?: number,
    clearCache?: boolean,
    onCacheCleared?: () => void,
    postId?: number,
    accessCacheOnly?: boolean,
}

export const PostResponseContextProvider: React.FC<PostContextProviderProps> = ({userId, postId, clearCache, accessCacheOnly, onCacheCleared, children}) => {
    const [postResponseState, setPostResponseState] =
        useState<PostResponse|undefined>(postId ? cachedPostResponses[postId] : undefined);
    const [instanceKey, _] = useState(postId + "-" + Math.random().toString(36).slice(2, 7))

    const setPostResponse = (postResponse?: PostResponse) => {
        if (postResponse && postId) {
            cachedPostResponses[postId] = postResponse
            const keys = Object.keys(subscriptions)
            keys.forEach(key => {
                const maybeId = key.split('-')[0]

                if (maybeId && Number.parseInt(maybeId) == postId) {
                    subscriptions[key](postResponse)
                }
            })
        }
    }

    useEffect(() => {
        if (postId) {
            subscriptions[instanceKey] = setPostResponseState
        }
        return () => {
            delete subscriptions[instanceKey]
        }
    }, []);

    useEffect(() => {
        if (clearCache) {
            cachedPostResponses = {}
            Object.values(subscriptions).forEach(sub => sub(undefined))
            if (onCacheCleared) {
                onCacheCleared()
            }
        }
    }, [clearCache]);

    useEffect(() => {
        if (!accessCacheOnly && !postResponseState && userId && postId) {
            UserPostRequests.searchForPostResponse(userId, postId).then(page => {
                if (page.data.length) {
                    setPostResponse(page.data[0])
                } else {
                    setPostResponse({...defaultPostResponse})
                }
            })
        }
    }, [postResponseState, postId])

    const state = {
        postResponse: postResponseState,
        setPostResponse
    }

    return (
        <PostResponseContext.Provider value={state}>
            {children}
        </PostResponseContext.Provider>
    )
}
