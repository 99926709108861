import {GeoLocationContextState} from '../../components/GeoLocationContextProvider/interfaces';
import Category from "../../models/category";

export interface TokenState {
    token: string;
    receivedAt: number;
}

export interface FeedSettings {
    radius: number;
    showMembers: boolean;
    showBusinesses: boolean;
}

export default interface PersistentState {
    hasSeenSwipeTutorial: boolean,
    hasSeenSavedTutorial: boolean,
    hasSeenReferralsTutorial: boolean;
    hasSeenIntroductionTutorial: boolean;
    hasRespondedToLocationPrompt: boolean;
    hasRespondedToNotificationPrompt: boolean;
    feedSettings: FeedSettings;
    taggedCategories: Category[],
    managingBusinessId?: number;
    firebaseToken?: string;
    tokenData?: TokenState;
    lastKnownLocation?: GeoLocationContextState,
}

export const initialFeedSettings: FeedSettings = {
    radius: 16.0934,
    showBusinesses: true,
    showMembers: true,
}

export const initialPersistentState: PersistentState = {
    hasSeenSwipeTutorial: false,
    hasSeenSavedTutorial: false,
    hasSeenReferralsTutorial: false,
    hasSeenIntroductionTutorial: false,
    hasRespondedToLocationPrompt: false,
    hasRespondedToNotificationPrompt: false,
    feedSettings: initialFeedSettings,
    taggedCategories: [],
}
