import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Collection from "../models/user/collection";

/**
 * The state interface for our state
 */
export interface UserCollectionsContextState extends BasePaginatedContextState<Collection> {}

let persistentContext = createDefaultState();

function createDefaultState(): UserCollectionsContextState {
    return {
        ...defaultBaseContext(),
        loadAll: true,
        order: {
            'created_at': 'desc',
        },
        limit: 50,
    }
}

/**
 * The actual context component
 */
export const UserCollectionsContext = React.createContext<UserCollectionsContextState>(createDefaultState());

export interface UserCollectionsContextProviderProps extends BasePaginatedContextProviderProps{
    userId: number,
}

export const UserCollectionsContextProvider: React.FC<UserCollectionsContextProviderProps> = ({userId, children}) => {
    const [userCollectionsState, setUserCollectionsState] = useState(persistentContext);
    useEffect(() => {
        prepareContextState(setUserCollectionsState, userCollectionsState, '/users/' + userId + '/collections')
    }, [userId]);

    const fullContext = {
        ...persistentContext,
        ...createCallbacks(setUserCollectionsState, persistentContext, '/users/' + userId + '/collections')
    }

    return (
        <UserCollectionsContext.Provider value={fullContext}>
            {children}
        </UserCollectionsContext.Provider>
    )
}
