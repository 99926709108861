import React from 'react'

import {
	IonContent, IonItem, IonList,
	IonPage, IonSelectOption,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import './index.scss';
import '@ionic/react/css/ionic-swiper.css';
import {TopicsContext, TopicsContextProvider, TopicsContextState} from "../../../contexts/TopicsContext";
import PostSwiper from "../../../components/PostSwiper";
import {SwiperSlide} from "swiper/react";
import TopicsList from "../../../components/TopicsList";


const Topics: React.FC = () => {

	return (
		<IonPage id={'topics-page'}>
			<LoggedInHeaderComponent>
			</LoggedInHeaderComponent>
			<IonContent>
				<h1>Refine your interests</h1>
				<p>Adjust your filters by updating what topics and accounts you're following.</p>
				<PostSwiper firstLabel={'Suggestions'}>

					<SwiperSlide>
						<TopicsContextProvider>
							<TopicsContext.Consumer>
								{topicsContext =>
									<TopicsList topics={topicsContext.loadedData}/>
								}
							</TopicsContext.Consumer>
						</TopicsContextProvider>
					</SwiperSlide>
				</PostSwiper>
			</IonContent>
		</IonPage>
	);
}

export default Topics;
