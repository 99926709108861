import React, {useEffect, useRef, useState} from 'react';
import {
    IonAlert,
    IonAvatar,
    IonButton,
    IonIcon, IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading, IonRadio, IonRadioGroup, IonToast, IonToggle,
} from '@ionic/react';
import './index.scss';
import {FacebookLogin, FacebookLoginResponse} from '@capacitor-community/facebook-login';
import fbApi from '../../../services/fb-api';
import MyBusinessContextProvider, {MyBusinessContext} from '../../../contexts/organization/MyBusinessContext';
import Business from '../../../models/organization/business';
import SocialMediaConnection from '../../../models/organization/social-media-connection';
import OrganizationRequests from '../../../services/requests/OrganizationRequests';
import {helpCircleOutline, repeatOutline} from 'ionicons/icons';
import BaseEditPageTemplateWrapper from '../../../components/OrganizationManagement/BaseEditPageTemplate';
import { Capacitor } from '@capacitor/core';

interface TokenData {
    token: string,
    tokenReceivedAt: string,
}

interface AccountData {
    id: string,
    profile_picture_url: string,
    name: string,
    access_token: string,
}

interface AccountSelectorProps extends TokenData {
    accounts: AccountData[],
    type: string,
    onAccountSelected: (connect: SocialMediaConnection) => void,
    business: Business,
}

const AccountSelector: React.FC<AccountSelectorProps> = ({accounts, business, type, token, tokenReceivedAt, onAccountSelected}) => {

    const [saving, setSaving] = useState(false);
    const connectAccount = (account: AccountData) => {
        setSaving(true)
        OrganizationRequests
            .connectSocialMediaAccount(business.id!, type, account.access_token, tokenReceivedAt, account.id)
            .then(onAccountSelected)
    }

    useEffect(() => {
        if (accounts.length == 1) {
            connectAccount(accounts[0]);
        }
    }, [accounts.length])

    return (saving ?
        <IonLoading isOpen={true} message={'Please wait. Connecting your account.'}/> :
        (accounts.length > 1 ?
            <div>
                <h2>Select Account</h2>
                <p>Only one {type.toLocaleUpperCase()} account can be connected to your Geddit Local account at a time.</p>
                <IonList>
                    {accounts.map(account => (
                        <IonItem onClick={() => connectAccount(account)}>
                            <IonAvatar slot="start">
                                <img alt="Silhouette of a person's head" src={account.profile_picture_url} />
                            </IonAvatar>
                            <IonLabel>
                                {account.name}
                            </IonLabel>
                        </IonItem>
                    ))}
                </IonList>
            </div> : <React.Fragment/>
        )
    )
}

interface PageData {
    id: string,
    access_token: string,
}

interface AccountProcessorProps extends InstagramLoginProps, TokenData{
    pageData: PageData[],
    onAccountError: (error: string) => void,
    onCancel: () => void,
}

const AccountProcessor: React.FC<AccountProcessorProps> = ({token, pageData, onAccountConnected, onAccountError, onCancel, ...rest}) => {

    const [facebookAccounts, setFacebookAccounts] = useState<any[]>([]);
    const [instagramAccounts, setInstagramAccounts] = useState<any[]>([]);
    const [instagramAccountsErrorShowing, setInstagramAccountsErrorShowing] = useState(false);

    const [connectedFacebookAccount, setConnectedFacebookAccount] = useState<any|undefined>();
    const [connectedInstagramAccount, setConnectedInstagramAccount] = useState<any|undefined>();

    useEffect(() => {
        let loadedFacebookAccounts: AccountData[] = [];
        let loadedInstagramAccounts: AccountData[] = [];
        pageData.forEach(data => {

            fbApi.get('/' + data.id, {
                params: {
                    access_token: token,
                    fields: 'name,picture,instagram_business_account{id,name,username,profile_picture_url}',
                },
            }).then(result => {
                const resultData = result.data;
                if (resultData.instagram_business_account) {
                    loadedInstagramAccounts.push({
                        ...resultData.instagram_business_account,
                        access_token: token,
                    });
                    setInstagramAccounts([...loadedInstagramAccounts])
                }
                loadedFacebookAccounts.push( {
                    name: resultData.name,
                    profile_picture_url: resultData.picture?.data?.url,
                    id: resultData.id,
                    access_token: data.access_token
                })
                setFacebookAccounts([...loadedFacebookAccounts])
            }).catch(error => {
                onAccountError('Unable to load account. Please make sure that you have sufficient access to the related Facebook page.');
                console.error('instagram error', error);
            })
        })
    }, [pageData])

    useEffect(() => {

        if (facebookAccounts.length === pageData.length && !instagramAccounts.length) {
            setInstagramAccountsErrorShowing(true);
        }

    }, [facebookAccounts.length])

    const onFacebookAccountConnected = (connection: SocialMediaConnection) => {
        if (instagramAccounts.length < 0) {
            onAccountConnected(connection);
        } else if (connectedInstagramAccount)  {
            onAccountConnected(connection, connectedInstagramAccount);
        } else {
            setConnectedFacebookAccount(connection);
        }
    }
    const onInstagramAccountConnected = (connection: SocialMediaConnection) => {
        if (connectedFacebookAccount)  {
            onAccountConnected(connectedFacebookAccount, connection);
        } else {
            setConnectedInstagramAccount(connection);
        }
    }

    return (pageData.length == facebookAccounts.length ?
        instagramAccountsErrorShowing ?
            <IonAlert
                isOpen={instagramAccountsErrorShowing}
                onDidDismiss={() => setInstagramAccountsErrorShowing(false)}
                header={'Warning'}
                message={'We were unable to load any data related to an instagram account. You can continue connecting your facebook account, but you will be unable to utilize our instagram features. If you feel that this is in error, please cancel and try again making sure to select the right instagram account, and all permissions. If this issue persists, please contact us at info@gedditlocal.com.'}
                buttons={[{
                    text: 'Cancel',
                    handler: onCancel,
                }, 'Continue']}
            /> :
            <React.Fragment>
                {!connectedFacebookAccount && <AccountSelector accounts={facebookAccounts} type={'facebook'} onAccountSelected={onFacebookAccountConnected} token={token} {...rest}/>}
                {connectedFacebookAccount && <AccountSelector accounts={instagramAccounts} type={'instagram'} onAccountSelected={onInstagramAccountConnected} token={token} {...rest}/>}
            </React.Fragment> :
        <IonLoading isOpen={true} message={'Please wait. Loading account information.'}/>
    )
}

interface InstagramLoginProps {
    onAccountConnected: (facebookConnection: SocialMediaConnection, instagramConnection?: SocialMediaConnection) => void,
    business: Business,
}

interface SignInData {
    pageData: PageData[],
    token: string,
    tokenReceivedAt: string,
}

const InstagramLogin: React.FC<InstagramLoginProps> = ({onAccountConnected, business}) => {

    const [signInData, setSignInData] = useState<SignInData|undefined>(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleError = (newError: string) => {
        setLoading(false)
        setSignInData(undefined);
        setError(newError);
    }

    const handleFacebookResult = (result: FacebookLoginResponse) => {
        if (!result) {
            handleError('Unable to connect account. Please make sure you have given Geddit Local access to your Instagram business account, and associated page while logging in through Facebook.');
        } else if (!result.accessToken) {
            handleError('Unable to connect account. Please try again.');
        } else {
            setLoading(true);
            const token = result.accessToken.token;
            const tokenRefreshedAt = result.accessToken.lastRefresh!;
            fbApi.get('/me/accounts', {
                params: {
                    access_token: token,
                }
            }).then((result) => {
                const pageData = result.data.data;
                if (!pageData.length) {
                    handleError('Unable to load Instagram accounts. Please make sure that you have selected both your facebook page and related instagram account. Also make sure to keep all permissions turned on during the login process.')
                } else {
                    setSignInData({
                        token: token,
                        tokenReceivedAt: tokenRefreshedAt,
                        pageData: pageData,
                    })
                    setLoading(false);
                }
            }).catch(error => {
                handleError('Unable to load your account information. Please make sure your facebook account is connected to a page if you want to post to Facebook, and then make sure your page is connected to an Instagram business account if you want to post to Instagram.');
                console.error('init error', error)
            })
        }
    }

    const signIn = () => {
        const permissions = ['instagram_basic', 'instagram_content_publish', 'pages_show_list', 'pages_manage_posts', 'pages_read_engagement'];
        if (Capacitor.isNativePlatform()) {
            FacebookLogin.login({permissions}).then(handleFacebookResult).catch(error => {
                handleError('Unable to start Facebook login.');
                console.error('instagram error', error)
            });
        } else {
            try {
                FB.login((response: facebook.StatusResponse) => {
                    if (response.status != "connected" || !response.authResponse || !response.authResponse.accessToken) {
                        handleError('Invalid login response. Please try again.');
                    } else {
                        handleFacebookResult({
                            accessToken: {
                                token: response.authResponse.accessToken,
                                lastRefresh: new Date().toISOString(),
                            }
                        })
                    }
                }, {
                    scope: permissions.join(','),
                })
            } catch (e) {
                handleError('Invalid login response. Please try again.');
            }
        }
    }

    return (
        <BaseEditPageTemplateWrapper
            title={'Instagram Connection'}
            customLayout
            id={'instagram-connection'}
        >
            {loading ?
                <IonLoading isOpen={loading} message={"Please wait. Getting your account information."} /> :

                (signInData ?
                    <AccountProcessor
                        token={signInData.token}
                        tokenReceivedAt={signInData.tokenReceivedAt}
                        pageData={signInData.pageData}
                        onAccountError={handleError}
                        onAccountConnected={onAccountConnected}
                        business={business}
                        onCancel={() => setSignInData(undefined)}
                    /> :
                    <div className={'sign-in-content'}>
                        <div className={'connection-logos'}>
                            <img src={'/assets/main-logo.svg'}/>
                            <IonIcon icon={repeatOutline}/>
                            <img src={'/assets/instagram-logo.svg'}/>
                        </div>
                        <h2>Connect your Meta account</h2>
                        <p>By connecting your Geddit Local account to your Instagram business account, you can sync your posts automatically from one platform to the other. In order to complete the IG connection, we first need to connect to your Meta account using Facebook.</p>
                        <button onClick={signIn} className={'facebook-login'}/>
                        <IonAlert
                            isOpen={error.length > 0}
                            onDidDismiss={() => setError('')}
                            message={error}
                            buttons={['OK']}
                        />
                    </div>
                )
            }
        </BaseEditPageTemplateWrapper>
    )
}

type PublishSchedule =  'pend' | 'immediately';

interface InstagramConnectionSettingsProps {
    instagramConnection: SocialMediaConnection,
    updateInstagramConnection: (data: any) => void,
    onInstagramDataUpdated: (data: any) => void,
    onSubmitted: (data: any) => void,
}

const InstagramConnectionSettings: React.FC<InstagramConnectionSettingsProps> = ({instagramConnection, updateInstagramConnection, onInstagramDataUpdated, onSubmitted}) => {
    const [instagramData, setInstagramData] = useState(instagramConnection);
    const [publishSchedule, setPublishSchedule] = useState<PublishSchedule>(instagramData.pending_minutes > 0 ? 'pend' : 'immediately');

    const [helpShowing, setHelpShowing] = useState(false);

    const changeAutoPublish = () => {
        const data = {
            ...instagramData,
            auto_publish: !instagramData.auto_publish,
        };
        setInstagramData(data);
        updateInstagramConnection(data);
    }

    const changePublishingSchedule = (schedule: PublishSchedule) => {
        setPublishSchedule(schedule);
        const data = {
            ...instagramData,
            pending_minutes : schedule == 'immediately' ? 0 : 15,
        };
        setInstagramData(data)
        updateInstagramConnection(data);
    }

    useEffect(() => {
        onInstagramDataUpdated(instagramData)
    }, [instagramData])

    const saveForm = (event: any) => {
        event.preventDefault();

        if (instagramData) {
            onSubmitted(instagramData);
        }
    };


    return (
        <form onSubmit={saveForm}>
            <h2>Instagram Connection Settings</h2>

            <IonItem className={'connection-toggle'} lines={'none'}>
                <div>Automatically publish posts from Instagram to Geddit Local?</div>
                <IonToggle
                    checked={instagramData.auto_publish}
                    onClick={() => changeAutoPublish()}
                    slot={'end'}
                />
            </IonItem>
            {instagramData.auto_publish ?
                <div className={'publish-schedule'}>
                    <h4>
                        Publish posts from Instagram...
                        <IonIcon icon={helpCircleOutline} onClick={() => setHelpShowing(true)}/>
                    </h4>
                    <IonRadioGroup value={publishSchedule} onIonChange={event => changePublishingSchedule(event.detail.value)}>
                        <IonItem>
                            <IonLabel>Immediately</IonLabel>
                            <IonRadio value={'immediately'}/>
                        </IonItem>
                        <IonItem>
                            <IonLabel>After...</IonLabel>
                            <IonRadio value={'pend'}/>
                        </IonItem>
                    </IonRadioGroup>
                    {publishSchedule == 'pend' &&
                        <div className={'minutes-input-wrapper'}>
                            <IonInput
                                value={instagramData.pending_minutes ? instagramData.pending_minutes : undefined}
                                onIonChange={event => setInstagramData({
                                    ...instagramData,
                                    pending_minutes: event.detail.value ? Number.parseInt(event.detail.value) : 0,
                                })}
                                type={'number'}
                                slot={'start'}
                            />
                            <IonLabel>Minutes</IonLabel>
                        </div>
                    }
                    <p><i>Posts that come from Instagram are scheduled to expire one week from the time they're published. This can be changed for any individual post by editing the post from your Activity page.</i></p>
                </div> : ''
            }
            <IonAlert
                onDidDismiss={() => setHelpShowing(false)}
                isOpen={helpShowing}
                buttons={['Okay']}
                message={'You can choose to share your Instagram posts immediately, or add a delay to allow time for editing in Geddit Local before publishing. For example, you may want to add a call-to-action button in Geddit Local, which is not available in Instagram.'}
            />
        </form>
    )
}

interface ConnectionSettingsProps {
    onConnectionUpdated: (connection: SocialMediaConnection) => void,
    onConnectionRemoved: () => void,
    instagramConnection?: SocialMediaConnection,
}

const ConnectionSettings: React.FC<ConnectionSettingsProps> = ({instagramConnection, onConnectionUpdated, onConnectionRemoved}) => {

    const [disconnectWarningShowing, setDisconnectWarningShowing] = useState(false);
    const [instagramData, setInstagramData] = useState(instagramConnection);
    const [runSave, setRunSave] = useState(false);

    const isDirty = (data: SocialMediaConnection): boolean => {
        return !!instagramConnection && data.pending_minutes !== instagramConnection.pending_minutes && data.pending_minutes > 0
    }

    const updateInstagramConnection = async (data = instagramData) => {

        if (data) {
            if (isDirty(data)) {
                await OrganizationRequests.updateSocialMediaConnection(instagramConnection!, data!.auto_publish, data!.pending_minutes)
                    .then(onConnectionUpdated);
            } else {
                onConnectionUpdated(data)
            }
        }
    }

    const formSubmitted = (data = instagramData) => {
        setInstagramData(data);
        setRunSave(true)
    }

    return (
        <BaseEditPageTemplateWrapper
            title={'Instagram Connection'}
            isDirty={instagramData ? isDirty(instagramData) : false}
            onSave={instagramConnection ? updateInstagramConnection : undefined}
            customLayout
            id={'instagram-connection'}
            runSave={runSave}
            onSaveComplete={() => setRunSave(false)}
        >
            {instagramConnection ?
                <InstagramConnectionSettings
                    instagramConnection={instagramConnection}
                    updateInstagramConnection={updateInstagramConnection}
                    onInstagramDataUpdated={setInstagramData}
                    onSubmitted={formSubmitted}
                /> :
                <p>It looks like you have only connected your facebook page. You can publish your posts to facebook. In order to gain full use of the app, you may want to reconnect your account and make sure to connect your Instagram account</p>
            }
            <IonButton
                onClick={() => setDisconnectWarningShowing(true)}
                color={'danger'}
            >
                Disconnect Instagram
            </IonButton>
            <IonAlert
                onDidDismiss={() => setDisconnectWarningShowing(false)}
                isOpen={disconnectWarningShowing}
                buttons={[
                    'Cancel',
                    {
                        text: "Ok",
                        handler: onConnectionRemoved
                    }
                ]}
                message={'By disconnecting from Meta you will no longer be able to use your Geddit Local account to post to Instagram and Facebook. Simply reconnect your accounts in the future to enable this feature again.'}
            />
        </BaseEditPageTemplateWrapper>
    )
}

interface InstagramContentProps {
    business: Business,
    setBusiness: (business: Business) => void,
}

const InstagramContent: React.FC<InstagramContentProps> = ({business, setBusiness }) => {

    const updatedConnection = (connection: SocialMediaConnection) => {
        const socialMediaConnections =
            business.social_media_connections.map(i => i.id == connection.id ? connection : i);
        if (!socialMediaConnections.includes(connection)) {
            socialMediaConnections.push(connection)
        }
        setBusiness({
            ...business,
            social_media_connections : socialMediaConnections,
        });
    }

    const connectionCreated = (facebookConnection: SocialMediaConnection, instagramConnection?: SocialMediaConnection) => {
        deleteConnectionData();
        setBusiness({
            ...business,
            social_media_connections: [
                facebookConnection,
                ...(instagramConnection ? [instagramConnection] : []),
            ]
        })
    }

    const deleteConnectionData = () => {
        setBusiness({
            ...business,
            social_media_connections : [],
        });
        business.social_media_connections.forEach(OrganizationRequests.deleteSocialMediaConnection)
    }

    const removeConnection = () => {
        deleteConnectionData();
        if (Capacitor.isNativePlatform()) {
            FacebookLogin.logout().catch(console.error);
        } else {
            FB.logout();
        }
    }

    const instagramConnection = business.social_media_connections
        .filter(i => i.type == 'instagram').pop()
    const facebookConnection = business.social_media_connections
        .filter(i => i.type == 'facebook').pop()

    return (
        facebookConnection ?
            <ConnectionSettings
                onConnectionUpdated={updatedConnection}
                onConnectionRemoved={removeConnection}
                instagramConnection={instagramConnection}
            /> :
            <InstagramLogin
                onAccountConnected={connectionCreated}
                business={business}
            />
    );

}

const Instagram: React.FC = () => {

    useEffect(() => {

        if (!Capacitor.isNativePlatform()) {
            const script = document.createElement("script");
            script.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js");
            script.setAttribute("async", "false");
            const head = document.head;
            head.insertBefore(script, head.firstChild);
            window.fbAsyncInit = function () {
                FB.init({
                    appId: '809927370246853',
                    autoLogAppEvents: true,
                    xfbml: true,
                    version: 'v15.0'
                });
            };
        }
    });

    return (
        <MyBusinessContextProvider>
            <MyBusinessContext.Consumer>
                {myBusinessContext =>
                    <InstagramContent
                        business={myBusinessContext.business}
                        setBusiness={myBusinessContext.setBusiness}
                    />
                }
            </MyBusinessContext.Consumer>
        </MyBusinessContextProvider>
    );
}

export default Instagram
