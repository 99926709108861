import React, {useState} from 'react'
import {Link} from 'react-router-dom'

import {
	IonButton,
	IonContent,
	IonIcon,
	IonItem,
	IonPage,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import AccountSwitcher from '../../../components/AccountSwitcher';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import './index.scss';
import {
	UserFollowsContext,
	UserFollowsContextProvider
} from '../../../contexts/UserFollowsContext';
import {chevronBack} from 'ionicons/icons';
import UserProfileImageUploader from '../../../components/UserProfileImageUploader';
import {logOut} from '../../../data/persistent/persistent.actions';
import {connect} from '../../../data/connect';
import {clearLoadingCount} from '../../../data/session/session.actions';
import {settings} from 'ionicons/icons'
import TutorialMessageComponent from '../../../components/Tutorials/TutorialMessageComponent';
import {useLocation, useHistory} from 'react-router';

import '@ionic/react/css/ionic-swiper.css';

import PostSwiper from "../../../components/PostSwiper";
import User from "../../../models/user/user";
import {UserFollowersContext, UserFollowersContextProvider} from '../../../contexts/UserFollowersContext';
import SideBySideComponent from '../../../components/SideBySide';
import SendInvitationModal from '../../../components/MembersPages/SendInvitationModal';
import {SwiperSlide} from 'swiper/react';
import UserRecommendations from '../../../components/UserRecommendations';
import SavedPosts from '../../../components/MembersPages/SavedPosts';

interface AcceptedInvitesProps{
	user: User
}

const AcceptedInvites: React.FC<AcceptedInvitesProps> = ({user}) => {
	// Once Inviting is setup on the server, replace the followers_count variable with the accepted invites variable
	return user.accepted_invites > 0 ? (
		<Link className="accepted-link" to={'/home/dashboard/account/accepted-invitations/'}>{user.accepted_invites} Accepted</Link>
	) : (
		<p>0 Accepted</p>
	)
}

interface StateProps {
	hasSeenReferralsTutorial:boolean,
}

interface DispatchProps {
	logOut: typeof logOut,
	clearLoadingCount: typeof clearLoadingCount,
}

export interface AccountProps extends StateProps, DispatchProps {}

const AccountHome: React.FC<AccountProps> = ({hasSeenReferralsTutorial}) => {
	const navigate = useHistory();

	const [hasScrolled, setHasScrolled] = useState(false)
	const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

	const onContentScroll = (event: any) => {
		if(!hasScrolled && event.detail.scrollTop > 0){
			setHasScrolled(true)
		}
		else if(hasScrolled && event.detail.scrollTop <= 0) {
			setHasScrolled(false)
		}
	}

	return (
		<IonPage id={'account-page'}>
			<LoggedInHeaderComponent
				hideBackButton={true}
				iconType={chevronBack}
				orangeHeader
				rightIcon={{
					icon : settings,
					handler : () => navigate.push(`/home/dashboard/account/settings`),
					mobileOnly : true,
				}}
			>
				<div className={'hide-in-desktop'}>
					<MeContextProvider hideLoadingSpace={true}>
						<MeContext.Consumer>
							{meContext => (
								<AccountSwitcher selectedText={meContext.me.full_name}/>
							)}
						</MeContext.Consumer>

					</MeContextProvider>
				</div>
				<div className={'hide-in-mobile'}>
					Profile
				</div>
			</LoggedInHeaderComponent>
			<IonContent scrollEvents onIonScroll={onContentScroll}>
				<MeContextProvider>
					<MeContext.Consumer>
						{meContext => (
							<React.Fragment>
								<div className={'account-header'} slot="fixed">
									<div className={"account-stripe"}/>
									<UserProfileImageUploader meContext={meContext} showPlus={true} isScrolled={hasScrolled} whiteBorder/>
								</div>
								<SideBySideComponent>
									<div className={'following-totals'}  onClick={() => navigate.push(`/home/dashboard/account/following`)}>
										<p>
											<strong>
												<UserFollowsContextProvider userId={meContext.me.id!}>
													<UserFollowsContext.Consumer>
														{userFollowsContext => (
															userFollowsContext.initialLoadComplete ?
																userFollowsContext.total : '-'
														)}
													</UserFollowsContext.Consumer>
												</UserFollowsContextProvider>
											</strong>
										</p>
										<p>Following</p>
									</div>
									<div className={'following-totals'}>
										<p onClick={() => navigate.push(`/home/dashboard/account/followers`)}>
											<strong>
												<UserFollowersContextProvider userId={meContext.me.id!}>
													<UserFollowersContext.Consumer>
														{userFollowedBusinessContext => (
															userFollowedBusinessContext.initialLoadComplete ?
																userFollowedBusinessContext.total : '-'
														)}
													</UserFollowersContext.Consumer>
												</UserFollowersContextProvider>
											</strong>
										</p>
										<p>Followers</p>
									</div>
								</SideBySideComponent>
								<div id={'add-bio-link'}>
									<div> {meContext.me.about_me} </div>
									<Link to={`/home/dashboard/account/edit-bio`} >
										{meContext.me.about_me ? 'Edit' : '+Add a'} bio
									</Link>
								</div>
								<h2>Recommendations</h2>
								<UserRecommendations
									user={meContext.me}
									me={meContext.me}
									noPostsMessage={'You have not made any recommendations yet'}
								/>
								{/*<div className={"user-invite"} slot={"fixed"}>*/}
								{/*	{meContext.me.invitation_token &&*/}
								{/*		<div className={'user-invite-friends-bar'}>*/}
								{/*			<AcceptedInvites user={meContext.me}/>*/}
								{/*			<IonButton*/}
								{/*				className={'follow-button'}*/}
								{/*				color={'primary'}*/}
								{/*				onClick={() => setIsInviteModalOpen(true)}*/}
								{/*			>*/}
								{/*				Invite Friends*/}
								{/*			</IonButton>*/}
								{/*			<SendInvitationModal*/}
								{/*				invitation={meContext.me.invitation_token}*/}
								{/*				isOpen={isInviteModalOpen}*/}
								{/*				onDidDismiss={() => setIsInviteModalOpen(false)}*/}
								{/*			/>*/}
								{/*		</div>*/}
								{/*	}*/}
								{/*</div>*/}
							</React.Fragment>
						)}
					</MeContext.Consumer>
				</MeContextProvider>
			</IonContent>
		</IonPage>
	);
}

const ConnectedAccountHome = connect<{}, { }, DispatchProps>({
	mapStateToProps: (state) => ({
		hasSeenReferralsTutorial: state.persistent.hasSeenReferralsTutorial
	}),
	mapDispatchToProps: ({
		logOut,
		clearLoadingCount,
	}),
	component: AccountHome,
});

export default ConnectedAccountHome;


