import {connect} from "../../../data/connect";
import React, {RefObject, useState} from "react";
import {FeedSettings} from "../../../data/persistent/persistent.state";
import {IonHeader, IonIcon, IonToolbar} from "@ionic/react";
import {optionsOutline} from "ionicons/icons";
import NewsFeedOptionsModal from "./NewsFeedOptionsModal";
import {makeKMReadableAsMiles} from "../../../util/location";
import "./index.scss"

interface OwnProps {
    isVisible: boolean
}
interface StateProps {
    feedSettings: FeedSettings,
}

interface NewsFeedSettingsProps extends StateProps, OwnProps {}

const NewsFeedSettings: React.FC<NewsFeedSettingsProps> = ({feedSettings, isVisible}) => {
    const [feedSettingsModalShowing, setFeedSettingsModalShowing] = useState(false);

    return (
        <IonHeader className={'news-feed-settings ion-no-border' + (!isVisible ? ' hide' : '')} slot={'fixed'}>
            <IonToolbar>
                <div className={'settings-information'}>

                    <div className={'settings-content'}>
                        <h2 className={'barlow'}>GEDDIT LOCAL</h2>
                        <p className={'barlow'}><strong>Current Location</strong> + {makeKMReadableAsMiles(feedSettings.radius)} mile radius</p>
                        <NewsFeedOptionsModal
                            isOpen={feedSettingsModalShowing}
                            onDidDismiss={() => setFeedSettingsModalShowing(false)}
                        />
                    </div>
                    <IonIcon
                        className={'options-button'}
                        icon={optionsOutline}
                        slot={'end'}
                        onClick={() => setFeedSettingsModalShowing(true)}
                    />
                </div>
            </IonToolbar>
        </IonHeader>
    )
}

export default connect<OwnProps, StateProps, {  }>({
    mapStateToProps: (state) => ({
        feedSettings: state.persistent.feedSettings
    }),
    component: NewsFeedSettings
})
