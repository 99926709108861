import React, {useCallback} from "react";
import InteractivePost, {InteractivePostOwnProps} from "../../Posts/InteractivePost";
import PostResponse from "../../../models/post/post-response";
import "./index.scss"
import {useResizeDetector} from "react-resize-detector";

interface NewsFeedPostProps extends InteractivePostOwnProps {
    onHeightSet: (height: number) => void,
}

const NewsFeedPost: React.FC<NewsFeedPostProps> = ({onPostRemoved, onHeightSet, ...rest}) => {

    const onResize = useCallback((width?: number,  height?: number) => {
        if (height) {
            onHeightSet(height)
        }
    }, []);

    const {  ref } = useResizeDetector({
        handleWidth: false,
        onResize
    });

    const onPostResponseChanged = (postResponse: PostResponse) => {
        if (postResponse.reported) {
            onPostRemoved();
        }
    }

    return (
        <div className={'news-feed-post'} ref={ref}>

            <InteractivePost
                onPostResponseChanged={onPostResponseChanged}
                onPostRemoved={onPostRemoved}
                {...rest}
            />
        </div>
    )
}

export default NewsFeedPost;