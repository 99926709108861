import React, {useState} from 'react';
import Post from '../../../models/post/post';
import './index.scss';
import GoogleLocalityDisplay from '../../GoogleLocalityDisplay';
import { Link } from 'react-router-dom';
import PostTextContentLocationDetails from '../PostTextContentLocationDetails';
import GooglePlaceLocationDisplay from '../GooglePlaceLocationDisplay';
import {ellipsisText} from "../../../util/strings";


interface PostTextContentProps {
    post: Post,
}

const ELLIPSIS_POSITION = 75;

const PostTextContent: React.FC<PostTextContentProps> = ({post}) => {

    const [expanded, setExpanded] = useState(false)
    const minPrice = (post.price ? post.price : '')
    const maxPrice = (post.max_price ? post.max_price : '')
    const priceParts =
        [minPrice, (minPrice && maxPrice) ? '-' : '', maxPrice]
        .filter(i => i.length)
    const postLocation =
        post.post_locations
            ?.filter(i => ['user', 'google_place', 'location'].indexOf(i.reference_type) != -1)
            .sort().find(() => true);
    const fullContent = post.article_content ?? '';

    const isUserPost = post.publisher_type === 'user'

    const availableLocation = post.locations && post.locations.length ? post.locations[0] : undefined;

    const needsSeeMore = fullContent.length > ELLIPSIS_POSITION;

    const currentContent = !needsSeeMore || expanded ?
        fullContent : ellipsisText(fullContent, ELLIPSIS_POSITION, '...');

    return (
        <div className={'post-text-content'}>
            {isUserPost && postLocation &&
                (postLocation.reference_type == 'google_place' ?
                    <GooglePlaceLocationDisplay postLocation={postLocation}/> :
                    (postLocation.reference_type == 'location' && availableLocation ? (
                        <Link to={`/home/dashboard/business/${availableLocation.business_id}/location/${availableLocation.id}`}>
                            <PostTextContentLocationDetails
                                name={availableLocation.name}
                                neighborhood={availableLocation.neighborhood}
                                city={availableLocation.city}
                                state={availableLocation.state}
                            />
                        </Link>) :
                        ((postLocation.latitude && postLocation.latitude) &&
                            <h4>
                                <GoogleLocalityDisplay
                                    latitude={postLocation.latitude!}
                                    longitude={postLocation.longitude!}
                                />
                            </h4>
                        )
                    )
                )
            }
            {post.title && <h4>{post.title}</h4>}
            {(priceParts.length && post.post_type != 'news') ?
                <h6>{priceParts.join(' ')}</h6> : undefined
            }
            <div className={'post-content-wrapper'}>
                <p className={isUserPost ? 'user-post-text' : ''}>
                    <span dangerouslySetInnerHTML={{__html: currentContent}} />
                    {needsSeeMore && !expanded ? <span className={'see-more-link'} onClick={() => setExpanded(true)}> see more</span> : ''}
                </p>
            </div>
        </div>
    )
}

export default PostTextContent;
