import React, {useEffect, useState} from 'react'

import {
	IonAlert,
	IonContent, IonIcon, IonItem, IonList,
	IonPage, IonToast, useIonViewDidLeave, useIonViewWillLeave,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import './index.scss';
import '@ionic/react/css/ionic-swiper.css';
import {useHistory, useLocation, useParams} from "react-router";
import {CollectionItemsContext, CollectionItemsContextProvider, CollectionItemsContextState} from "../../../../contexts/CollectionItemsContext";
import Post from "../../../../models/post/post";
import GooglePlaceLocationDisplay from "../../../../components/Posts/GooglePlaceLocationDisplay";
import AlbumPostImage from "../../../../components/Albums/AlbumPostImage";
import {arrowBack, bookmark, bookmarkOutline, pencil, trashBin, trashOutline} from "ionicons/icons";
import CollectionItem from "../../../../models/user/collection-items";
import CollectionManagementRequests from "../../../../services/requests/CollectionManagementRequests";
import {
	UserCollectionsContext,
	UserCollectionsContextProvider,
	UserCollectionsContextState
} from "../../../../contexts/UserCollectionsContext";
import MeContextProvider, {MeContext} from "../../../../contexts/MeContext";
import Collection from "../../../../models/user/collection";
import User from "../../../../models/user/user";
import {
	CACHE_REFRESH_WAIT_TIME, PostResponseContext,
	PostResponseContextConsumerState,
	PostResponseContextProvider
} from "../../../../contexts/PostResponseContet";

interface AlbumItemListItemProps {
	post: Post,
	hasSaved: boolean
	saveIconClicked: () => void
}

const AlbumItemListItem: React.FC<AlbumItemListItemProps> = ({post, hasSaved, saveIconClicked}) => {
	const maybeGooglePostLocation = post.post_locations?.find(i => i.reference_type == 'google_place');
	let postText: string|undefined = undefined

	const onIconClicked = (event: any) => {
		event.preventDefault()
		event.stopPropagation()
		saveIconClicked()
	}

	if (post.publisher_type == "user") {
		postText = (post.publisher as User).full_name
	} else {
		postText = post.locations?.find(() => true)?.name;
	}

	return (
		<IonItem lines={'none'} detail={false} routerLink={`/home/dashboard/post/${post.id}`} key={post.id} className={'post-item'}>
			<div className={'post-content'}>
				<IonIcon className={hasSaved ? 'saved-icon' : 'unsaved-icon'} icon={hasSaved ? bookmark : bookmarkOutline} onClick={onIconClicked}/>
				<AlbumPostImage imageUrl={post.main_image_url} noBorder/>
				<div className={'post-header-details'}>
					<p>
						{postText ? postText : (
							maybeGooglePostLocation ?
								<GooglePlaceLocationDisplay postLocation={maybeGooglePostLocation}/> : ''
						)}
					</p>
				</div>
			</div>
		</IonItem>
	)
}

interface AlbumItemListProps {
	userId: number,
	collectionItemsContext: CollectionItemsContextState
	removedCollectionItemsQueue: CollectionItem[]
	saveIconClicked: (collection: CollectionItem, postResponseContext: PostResponseContextConsumerState) => void
}
const AlbumItemList: React.FC<AlbumItemListProps> = ({userId, collectionItemsContext, removedCollectionItemsQueue, saveIconClicked}) => {

	const posts = collectionItemsContext.loadedData
		.filter(i => i.item)
		.map(i => ({
			collectionItem: i,
			post: i.item!
		}));

	const hasSaved = (postResponseContext: PostResponseContextConsumerState, collectionItem: CollectionItem): boolean => {
		if (postResponseContext.postResponse) {
			return postResponseContext.postResponse.saved
		}

		return !removedCollectionItemsQueue.map(i => i.id).includes(collectionItem.id!);
	}

	return collectionItemsContext.initialLoadComplete ? (
		collectionItemsContext.loadedData.length > 0 ?
			<IonList className={'album-post-list'}>
				{posts.map(i => (
					<PostResponseContextProvider
						key={i.collectionItem.id}
						accessCacheOnly
						postId={i.post.id!}
						userId={userId}
					>
						<PostResponseContext.Consumer>
							{postResponseContext =>
								<AlbumItemListItem
									post={i.post}
									hasSaved={hasSaved(postResponseContext, i.collectionItem)}
									saveIconClicked={() => {
										saveIconClicked(i.collectionItem, postResponseContext)
									}}
								/>
							}
						</PostResponseContext.Consumer>
					</PostResponseContextProvider>
				))}
			</IonList> :
			<h5 key={"no-posts"}>No Posts in this Album</h5>
	) : <React.Fragment/>
}

interface AlbumEditProps {
	isRenamingCollection: boolean,
	onCollectionRenamed: () => void,
	isDeletingCollection: boolean,
	onDeletionResult: (deleted: boolean) => void,
}

interface AlbumReadyProps extends AlbumEditProps {
	collection: Collection
	userCollectionsContext: UserCollectionsContextState,
	collectionItemsContext: CollectionItemsContextState,
}

const AlbumReady: React.FC<AlbumReadyProps> = ({collection, userCollectionsContext, collectionItemsContext, isDeletingCollection, onDeletionResult, isRenamingCollection, onCollectionRenamed}) => {

	const history = useHistory()

	const [toastMessage, setToastMessage] = useState('')
	const [removedCollectionItemsQueue, setRemoveCollectionItemsQueue] = useState([] as CollectionItem[])


	const deleteCollection = () => {
		CollectionManagementRequests.deleteCollection(collection).then(() => {
			onDeletionResult(true)
			userCollectionsContext.removeModel(collection);
		})
	}

	const renameCollection = (name: string) => {
		if (name.length > 0) {
			CollectionManagementRequests
				.updateCollection(collection, {name})
				.then(userCollectionsContext.addModel)
		}
	}

	const leavingPage = () => {

		removedCollectionItemsQueue.forEach((collectionItem) => {
			collectionItemsContext.removeModel(collectionItem)
		})
		setRemoveCollectionItemsQueue([])
	}

	const toggleRemovingPost =  (collectionItem: CollectionItem, postResponseContext: PostResponseContextConsumerState) => {
		const postIndex = removedCollectionItemsQueue
			.findIndex(removeCollectionItem => removeCollectionItem.id === collectionItem.id)
		let newRemoveCollectionItemsQueue = removedCollectionItemsQueue
		let newCollectionItemsCount = collection.collection_items_count ?? 1
		let saved = false


		// Not already marked for removal, remove from collection items and collection count, add to list
		if (postIndex < 0 || (postResponseContext.postResponse && postResponseContext.postResponse.saved)) {
			newCollectionItemsCount--
			newRemoveCollectionItemsQueue.push(collectionItem)

			const title = collectionItem.item && collectionItem.item.title ? collectionItem.item.title : '';

			setToastMessage('')
			setTimeout(() => {
				setToastMessage(`Removed ${title} from ${collection.name}`)

			}, 100)
		}
		// Already marked for removal, restore item and count, remove from list
		else {
			saved = true
			newCollectionItemsCount++
			newRemoveCollectionItemsQueue.splice(postIndex, 1)
		}

		collection.collection_items_count = newCollectionItemsCount

		CollectionManagementRequests.toggleCollectionItemRemoved(collectionItem).then(updated => {
			updated.item = collectionItem.item;
			collectionItemsContext.addModel(updated)
		})
		userCollectionsContext.addModel(collection)
		setRemoveCollectionItemsQueue(newRemoveCollectionItemsQueue)
		if (postResponseContext.postResponse) {
			postResponseContext.setPostResponse({
				...postResponseContext.postResponse,
				saved,
			})
		}
	}

	useEffect(() => {
		return history.listen((loc, action) => {
			if (loc.pathname.endsWith('/albums') && action === "POP") {
				leavingPage()
			}
		});
	}, []);

	return (
		<React.Fragment>
			<AlbumItemList
				userId={collection.owner_id}
				removedCollectionItemsQueue={removedCollectionItemsQueue}
				collectionItemsContext={collectionItemsContext}
				saveIconClicked={toggleRemovingPost}
			/>
			<IonAlert
				header={"Delete \"" + collection.name + "\""}
				message={"Are you sure you want to delete the album\"" + collection.name + "\"?"}
				isOpen={isDeletingCollection}
				onDidDismiss={() => onDeletionResult(false)}
				buttons={[
					"Cancel",
					{
						text: "Delete",
						role: "destructive",
						handler: () => deleteCollection()
					}
				]}
			/>
			<IonAlert
				header={"Rename Album"}
				isOpen={isRenamingCollection}
				inputs={[{
					placeholder: "Name",
					value: collection.name,
					attributes: {
						required: true
					}
				}]}
				onDidDismiss={() => onCollectionRenamed()}
				buttons={[
					"Cancel",
					{
						text: "Save",
						handler: (event) => renameCollection(event[0])
					}
				]}
			/>
			<IonToast
				id={'album-page-toast'}
				isOpen={!!toastMessage}
				duration={2000}
				message={toastMessage}
			/>
		</React.Fragment>
	)
}

interface CollectionSelectorProps extends AlbumEditProps {
	collectionId: number,
	userCollectionsContext: UserCollectionsContextState,
}

const CollectionSelector: React.FC<CollectionSelectorProps> = ({collectionId, userCollectionsContext, ...rest}) => {

	const maybeCollection = userCollectionsContext.loadedData.find(i => i.id == collectionId);

	return (maybeCollection ?
		<React.Fragment>
			<h2>{maybeCollection.name}</h2>
			<CollectionItemsContextProvider collectionId={maybeCollection.id!}>
				<CollectionItemsContext.Consumer>
					{collectionItemsContext => (
						<AlbumReady
							collection={maybeCollection}
							userCollectionsContext={userCollectionsContext}
							collectionItemsContext={collectionItemsContext}
							{...rest}
						/>
					)}
				</CollectionItemsContext.Consumer>
			</CollectionItemsContextProvider>
		</React.Fragment>
		:
		<h1>Album Does Not Exist</h1>
	)
}

type RouteParams = {
	collectionId: string,
}

const Album: React.FC = ({}) => {
	const history = useHistory();

	const {collectionId} = useParams<RouteParams>();

	const [isRenamingCollection, setIsRenamingCollection] = useState(false);
	const [isDeletingCollection, setIsDeletingCollection] = useState(false);
	const [refreshPostResponseCache, setRefreshPostResponseCache] = useState(false)

	const onDeleteCollectionResult = (deleted: boolean) => {
		if (deleted) {
			setTimeout(() => setRefreshPostResponseCache(true), CACHE_REFRESH_WAIT_TIME)
		} else {
			setIsDeletingCollection(false);
		}
	}

	return (
		<IonPage id={'album-page'}>
			<LoggedInHeaderComponent
				hideBackButton={false}
				blackHeader={true}
				iconType={arrowBack}
				ellipsisMenu={[{
					text: "Rename",
					handler: () => {
						setIsRenamingCollection(true)
					},
					color: "medium",
					icon: pencil,
				},{
					text: "Delete",
					handler: () => {
						setIsDeletingCollection(true)
					},
					color: "danger",
					icon: trashOutline,
				}]}
			/>
			<IonContent>
				<MeContextProvider>
					<MeContext.Consumer>
						{meContext =>
							<UserCollectionsContextProvider userId={meContext.me.id!}>
								<UserCollectionsContext.Consumer>
									{userCollectionsContext => (
										<PostResponseContextProvider clearCache={refreshPostResponseCache} onCacheCleared={history.goBack}>
											<CollectionSelector
												collectionId={parseInt(collectionId)}
												userCollectionsContext={userCollectionsContext}
												isDeletingCollection={isDeletingCollection}
												isRenamingCollection={isRenamingCollection}
												onCollectionRenamed={() => setIsRenamingCollection(false)}
												onDeletionResult={onDeleteCollectionResult}
											/>
										</PostResponseContextProvider>
									)}
								</UserCollectionsContext.Consumer>
							</UserCollectionsContextProvider>
						}
					</MeContext.Consumer>
				</MeContextProvider>

			</IonContent>
		</IonPage>
	);
}

export default Album;
