import React, {ReactElement, useEffect, useState} from 'react';
import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    useIonRouter,
    Gesture,
    createGesture, IonSplitPane, IonContent, IonMenu, IonItem
} from '@ionic/react';
import {Route, useLocation} from 'react-router';
import News from './News';
import Explore from './Explore';
import Account from './Account';
import ChangePassword from './Account/Settings/ChangePassword';
import Following from './Account/Following';
import FAQ from './Account/Settings/FAQ';
import TermsOfUse from './Account/Settings/TermsOfUse';
import PrivacyPolicy from './Account/Settings/PrivacyPolicy';
import NotificationSettings from './Account/Settings/NotificationSettings';
import Profile from './Account/Settings/Profile';
import ProfileEditor from './Account/Settings/Profile/ProfileEditor';
import './index.scss';
import ContactUs from './Account/Settings/ContactUs';
import Events from "./Events";
import MemberPostCreator from "./MemberPostCreator";
import AccountSettings from "./Account/Settings";
import AddBio from "./Account/AddBio"
import DeleteAccount from "./Account/Settings/DeleteAccount";
import Followers from './Account/Followers';
import UserPage from './User';
import PostPublished from './MemberPostCreator/PostPublished';
import PostPreviewPage from './MemberPostCreator/PostPreview';
import PrivacySettings from './Account/Settings/PrivacySettings';
import {imageOutline, search} from "ionicons/icons";
import {Redirect, useHistory} from 'react-router-dom';
import HomeDesktopMenu from '../../components/MembersPages/HomeDesktopMenu';
import {buildSubRoutes} from './route-helpers';
import PostPage from './Post';
import BusinessLocationPage from './BusinessLocation';
import ProfileImage from '../../components/ProfileImage';
import MeContextProvider, {MeContext} from '../../contexts/MeContext';
import PostInteractionsPage from './Post/PostInteractions';
import AcceptedInvitations from './Account/AcceptedInvitations';
import {setHasSeenIntroductionTutorial, setHasSeenReferralsTutorial} from '../../data/persistent/persistent.actions';
import {connect} from '../../data/connect';
import {TokenState} from '../../data/persistent/persistent.state';
import Albums from "./Albums";
import Topics from "./Topics";
import Album from "./Albums/Album";
import Topic from "./Topics/Topic";



const Dashboard: React.FC<HomeProps> = ({setHasSeenReferralsTutorial}) => {

    const location = useLocation();
    const history = useHistory();
    const [gesture, setGesture] = useState<Gesture|undefined>(undefined);

    useEffect(() => {
        const tabs = document.getElementsByClassName('home-tabs-content');
        let gestureInstance = gesture;
        if (!gesture && tabs.length) {
            gestureInstance = createGesture({
                el: tabs[0] as Node,
                threshold: 0,
                gestureName: 'my-gesture',
                gesturePriority: 40.5, // priority of swipe to go back is 40
            });
            setGesture(gestureInstance);
        }

        if (gestureInstance) {
            const disabledPages = [
                '/home/dashboard/account',
                '/home/dashboard/events',
                '/home/dashboard/news',
                '/home/dashboard/offers',
            ]
            gestureInstance.enable(disabledPages.indexOf(window.location.pathname) != -1);
        }

    }, [window.location.pathname])

    const ionRouter = useIonRouter();

    const onIonTabsDidChange = async (event: CustomEvent<{tab: string}>) => {

        if (event.detail.tab == 'account') {
            await new Promise(resolve => setTimeout(resolve, 50))
            if (window.location.pathname != `/home/dashboard/` + event.detail.tab) {
                ionRouter.push(`/home/dashboard/` + event.detail.tab, 'back', 'pop')
            }
        }
    }

    const goToPostCreate = (event: any) => {
        event.stopPropagation();
    }

    return (
        <IonTabs onIonTabsDidChange={onIonTabsDidChange} className={'home-tabs-content'}>
            <IonRouterOutlet>
                <Redirect exact path={'/home/dashboard'} to={`/home/dashboard/feed`} />
                <Route exact path={`/home/dashboard/account`} render={() => <Account/>}/>
                <Route exact path={`/home/dashboard/account/edit-bio`} render={() => <AddBio/>}/>
                <Route exact path={`/home/dashboard/account/followers`} render={() => <Followers/>} />
                <Route exact path={`/home/dashboard/account/following`} render={() => <Following/>} />
                <Route exact path={`/home/dashboard/account/settings`} render={() => <AccountSettings/>}/>
                <Route exact path={`/home/dashboard/account/settings/change-password`} render={() => <ChangePassword/>} />
                <Route exact path={`/home/dashboard/account/settings/contact-us`} render={() => <ContactUs/>}/>
                <Route exact path={`/home/dashboard/account/settings/delete-account`} render={() => <DeleteAccount/>}/>
                <Route exact path={`/home/dashboard/account/settings/faq`} render={() => <FAQ/>} />
                <Route exact path={`/home/dashboard/account/settings/terms`} render={() => <TermsOfUse/>} />
                <Route exact path={`/home/dashboard/account/settings/privacy-policy`} render={() => <PrivacyPolicy/>} />
                <Route exact path={`/home/dashboard/account/settings/notification-settings`} render={() => <NotificationSettings/>} />
                <Route exact path={`/home/dashboard/account/settings/privacy-settings`} render={() => <PrivacySettings/>} />
                <Route exact path={`/home/dashboard/account/settings/profile`} render={() => <Profile/>} />
                <Route exact path={`/home/dashboard/account/settings/profile/editor`} render={() => <ProfileEditor/>} />
                <Route exact path={`/home/dashboard/account/accepted-invitations`} render={() => <AcceptedInvitations/>} />

                <Route exact path={`/home/dashboard/albums`} render={() => <Albums/>}/>
                <Route exact path={`/home/dashboard/albums/:collectionId`} render={() => <Album/>}/>

                <Route path={`/home/dashboard/post-creator`} render={() => <MemberPostCreator/>}/>
                <Route path={`/home/dashboard/member-post-editor/:postId`} render={() => <MemberPostCreator/>}/>
                <Route path={`/home/dashboard/member-post-creator/published`} render={() => <PostPublished/>}/>
                <Route path={`/home/dashboard/member-post-creator/preview/:postId`} render={() => <PostPreviewPage/>}/>

                <Route exact path={`/home/dashboard/events`} render={() => <Events/>}/>

                <Route exact path={`/home/dashboard/explore`} render={() => <Explore/>}/>

                <Route path={`/home/dashboard/feed`} render={() => <News/>}/>
                <Route path={`/home/dashboard/feed/*`} render={() => <News/>}/>

                <Route exact path={`/home/dashboard/business/:businessId/location/:locationId`} render={() => <BusinessLocationPage/>}/>
                <Route exact path={`/home/dashboard/post/:postId`} render={() => <PostPage/>}/>
                <Route exact path={`/home/dashboard/post/:postId/:interaction`} render={() => <PostInteractionsPage/>}/>
                <Route exact path={`/home/dashboard/user/:userId`} render={() => <UserPage/>}/>
                <Route exact path={`/home/dashboard/topic/:topicId`} render={() => <Topic/>}/>
                <Route exact path={`/home/dashboard/topics`} render={() => <Topics/>}/>

            </IonRouterOutlet>
            <IonTabBar slot="bottom" className={'home-tabs hide-in-desktop'} >
                <IonTabButton tab='feed' href={`/home/dashboard/feed`}>
                    <IonIcon src={'/assets/app-icons/logo.svg'} />
                    Board
                </IonTabButton>
                <IonTabButton tab='explore' href={`/home/dashboard/explore`}>
                    <IonIcon icon={search} />
                    Explore
                </IonTabButton>
                <IonTabButton tab='post-creator' href={`/home/dashboard/post-creator`}>
                    <IonIcon src={'/assets/app-icons/add.svg'}/>
                    Post
                </IonTabButton>
                <IonTabButton tab='events' href={`/home/dashboard/albums`}>
                    <IonIcon src={'/assets/app-icons/bookmarks.svg'} />
                    Albums
                </IonTabButton>
                <IonTabButton tab='account' href={`/home/dashboard/account`}>
                    <MeContextProvider hideLoadingSpace>
                        <MeContext.Consumer>
                            {meContext => meContext.me.profile_image_url ?
                                <ProfileImage
                                    noBorder
                                    name={meContext.me.full_name}
                                    url={meContext.me.profile_image_url}
                                /> :  <IonIcon src={'/assets/app-icons/account.svg'} />
                            }
                        </MeContext.Consumer>
                    </MeContextProvider>

                    Profile
                </IonTabButton>
            </IonTabBar>
        </IonTabs>
    );
};

interface StateProps {
    hasSeenReferralsTutorial: boolean
}

interface DispatchProps {
    setHasSeenReferralsTutorial: typeof setHasSeenReferralsTutorial
}

interface HomeProps extends DispatchProps {}

const Home: React.FC<HomeProps> = (props) => {

    return (
        <IonSplitPane contentId="home" className={'home-splitpane'}>
            <HomeDesktopMenu/>
            <IonContent className={'home-content'} id={'home'} scrollY={false}>
                <IonRouterOutlet>
                    <Redirect exact path={'/home'} to={`/home/dashboard`} />
                    <Route path={`/home/dashboard`} render={() => <Dashboard {...props}/>}/>
                </IonRouterOutlet>
            </IonContent>
        </IonSplitPane>
    )
}

export default connect<{ } , StateProps,  DispatchProps >({
    mapStateToProps: (state) => ({
        hasSeenReferralsTutorial: state.persistent.hasSeenReferralsTutorial
    }),
    mapDispatchToProps: ({
        setHasSeenReferralsTutorial,
    }),
    component: Home
});



