import React from 'react';
import ProfileImage from '../../ProfileImage';
import './index.scss';
import Post from '../../../models/post/post';
import {IonIcon, IonItem} from '@ionic/react';
import {ellipsisText} from '../../../util/strings';
import {heart} from 'ionicons/icons';
import {formatTimeAgo} from '../../../util/time';
import {useHistory} from 'react-router';
import User from '../../../models/user/user';
import Location from "../../../models/location/location";
import GooglePlaceLocationDisplay from "../../Posts/GooglePlaceLocationDisplay";
import PostLocation from "../../../models/post/post-location";

interface PostLocationDetailsProps {
    location?: Location;
    googleLocation?: PostLocation;
}

const PostLocationDetails: React.FC<PostLocationDetailsProps> = ({location, googleLocation}) => {

    const locationDetails = location ? (
        <div className={"post-location-details"}>
            <ProfileImage url={location?.logo} noBorder slot={'start'} name={location?.name ?? ''}/>
            <p>
                <strong>{location?.name}</strong>
            </p>
        </div>
    ) : null

    return (googleLocation ? <GooglePlaceLocationDisplay postLocation={googleLocation}/> : locationDetails)

}

interface PostItemWithBusinessProps {
    post: Post,
    lines?: "full" | "inset" | "none",
    baseUrl?: string,
}

const PostItemWithLocation: React.FC<PostItemWithBusinessProps> = ({post, lines, baseUrl }) => {

    const history = useHistory();
    const link = (baseUrl ? baseUrl : '/home/dashboard') + ('/post/' + post.id);
    const post_locations = post.post_locations ?? [];

    const maybeGooglePostLocation = post_locations.find(i => i.reference_type == 'google_place');
    const maybeLocation = post.locations ? post.locations.find(() => true) : undefined;
    const maybeTitle = post.publisher_type == 'user' && post.publisher ? (post.publisher as User).full_name : post.title

    const openPost = () => {
        history.push(link);
    }

    return (
        <IonItem lines={lines ? lines : 'full'} className={'post-item-with-business'} detailIcon={'none'} onClick={openPost}>
            <div className={'post-item-details'}>
                <div className={'post-content'}>
                    <PostLocationDetails location={maybeLocation} googleLocation={maybeGooglePostLocation}/>
                    {maybeTitle ? <p><strong>{maybeTitle}</strong></p> : null}
                    <p dangerouslySetInnerHTML={{__html : post.article_content ? ellipsisText(post.article_content, maybeTitle ? 60 : 90, '...') : ''}}></p>
                </div>
                {post.main_image_url &&
                    <div className={'post-preview-thumbnail'}  style={{backgroundImage: `url(${post.main_image_url})`}}/>
                }
            </div>
        </IonItem>
    )
}

export default PostItemWithLocation;
