import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {App} from '@capacitor/app';
import {Geolocation, Position, PositionOptions} from '@capacitor/geolocation';
import {Device} from '@capacitor/device';
import {OpenNativeSettings} from '@ionic-native/open-native-settings';
import {GeoLocationContext} from '../index';
import PermissionModal from '../PermissionModal';
import {defaultState, GeoLocationContextProviderProps, GeoLocationContextState} from '../interfaces';
import LoadingScreen from '../../LoadingScreen';
import {calculateDistance} from '../../../util/location';
import NetworkBasedLocationContextProvider, {NetworkBasedLocationContext} from '../NetworkBasedLocationContextProvider';
import PermissionPrompt from "../PermissionPrompt";

let currentTimeoutId : any = undefined;
let restoreLocationTimeout: any = undefined;
let persistedState = defaultState

let geoLocationSubscriptions: {[key: string]: Dispatch<SetStateAction<GeoLocationContextState>>} = {};

const setPersistedState = (newState: GeoLocationContextState) => {
    persistedState = {...newState};
    Object.values(geoLocationSubscriptions).forEach(callback => callback(persistedState));
}

interface NativeGeoLocationContextProviderProps extends GeoLocationContextProviderProps {
    networkBasedLocation: GeoLocationContextState,
}

const NativeGeoLocationContextProvider: React.FC<NativeGeoLocationContextProviderProps> = ({locationRequired, networkBasedLocation, lastKnownLocation, setLastKnownLocation, forceRefresh, onLocationReceived, promptPermission, onResponseGiven, children, hasRespondedToLocationPrompt, setHasRespondedToLocationPrompt, privacyPolicyURL }) => {

    const [contextState, setContextState] = useState(persistedState);
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());
    const [isModalShowing, setIsModalShowing] = useState(!hasRespondedToLocationPrompt && promptPermission == true)

    const history = useHistory();

    useEffect(() => setIsModalShowing(promptPermission!!), [promptPermission]);

    useEffect(() => {
        geoLocationSubscriptions[instanceKey] = setContextState;

        return () => {
            delete geoLocationSubscriptions[instanceKey];
        }
    }, []);

    useEffect(() => {
        if (forceRefresh) {
            requestLocation().catch(console.error);
        }
    }, [forceRefresh])

    /**
     * We need this, because it will try the initialization of location again after the user comes back from the settings
     */
    useEffect(() => {
        App.addListener('appStateChange', status => {
            if (status.isActive) {
                if (contextState.status == 'in-settings') {
                    setPersistedState({
                        ...contextState,
                        status: 'initializing',
                    });
                } else {
                    if (contextState.receivedAt < Date.now() - 5 * 60 * 1000) {
                        requestLocation();
                    } else {
                        setRefreshTimeout();
                    }
                }
            } else {
                clearTimeout(currentTimeoutId);
            }
        });
    }, [])

    /**
     *
     * @param result
     */
    const isResultPermissionError = (result: any) : boolean => {
        const error = result.code !== undefined ? result as any: undefined;

        return error !== undefined && error.code === error.PERMISSION_DENIED;
    }

    /**
     *
     * @param result
     */
    const isResultUnsecureError = (result: any) : boolean => {
        const error = result.code !== undefined ? result as any : undefined

        return error !== undefined && error.code === error.PERMISSION_DENIED && error.message.indexOf('Only secure origins') > -1;
    }

    /**
     * Whether or not the location is unavailable
     * @param error
     */
    const isUnavailableError = (error: any) : boolean => {
        return error !== undefined && error.message.indexOf('unavailable') != -1;
    }

    // We can use a location up to 5 minutes old with minimal problems
    const locationUpdateOptions: PositionOptions = {
        maximumAge: 5 * 60 * 1000,
        enableHighAccuracy: true,
    }

    const setRefreshTimeout = () => {
        currentTimeoutId = setTimeout(() => requestLocation(), 5 * 60 * 1000);
    }

    const validGPSResult = (result: Position) => {
        return result && result.hasOwnProperty('coords');
    }

    const handleGeoLocationResult = (result: Position) => {
        if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
        }
        setRefreshTimeout();
        if (validGPSResult(result)) {

            const lastCoords = persistedState.location.coords;
            const newCoords = result.coords;

            if (!lastCoords || calculateDistance(newCoords.latitude, newCoords.longitude, lastCoords.latitude, lastCoords.longitude) > .2) {
                if (restoreLocationTimeout) {
                    clearTimeout(restoreLocationTimeout);
                }
                setPersistedState({
                    location: result,
                    receivedAt: Date.now(),
                    status: 'success',
                });
                setLastKnownLocation({
                    location: result,
                    receivedAt: Date.now(),
                    status: 'success',
                });
            }

            onLocationReceived && onLocationReceived();
        }
    }

    const handleGeoLocationError = async (error: any) => {
        if (!persistedState.location || !persistedState.location.coords) {
            const info = await Device.getInfo();
            if (info.operatingSystem === 'android' && process.env.NODE_ENV === 'development') {
                const position = {
                    timestamp: new Date().getUTCDate(),
                    coords: {
                        latitude: 43.0389,
                        longitude: -87.9065,
                        accuracy: NaN,
                        altitude: 188,
                        altitudeAccuracy: NaN,
                        heading: NaN,
                        speed: NaN,
                    }
                } as Position;
                setPersistedState({
                    location: position,
                    receivedAt: Date.now(),
                    status: 'success',
                });
            } else {

                if (isResultUnsecureError(error)) {
                    setPersistedState({
                        ...contextState,
                        status: 'failed',
                    });
                } else if (isResultPermissionError(error)) {
                    if (locationRequired) {
                        setIsModalShowing(true);
                    }
                    setPersistedState({
                        ...contextState,
                        status: 'denied',
                    });
                } else if (isUnavailableError(error)) {
                    setPersistedState({
                        ...contextState,
                        status: 'failed',
                    });
                } else {
                    setPersistedState({
                        ...contextState,
                        status: 'denied',
                    });
                }
            }
        }
    }

    /**
     * This function will request an actual location update for us
     */
    const requestLocation = async () => {

        if (restoreLocationTimeout) {
            clearTimeout(restoreLocationTimeout);
        }
        Geolocation.getCurrentPosition(locationUpdateOptions)
            .then(handleGeoLocationResult)
            .catch(handleGeoLocationError);
        // restoreLocationTimeout = setTimeout(() => {
        //     if (lastKnownLocation && Date.now() - lastKnownLocation.location.timestamp < (60 * 60 * 1000)) {
        //         setPersistedState({...lastKnownLocation});
        //     }
        // }, 5000);
    }

    const goToLocationSettings = (setting: string) => {

        OpenNativeSettings.open(setting).then(something => {
            setPersistedState({
                ...contextState,
                status: 'in-settings',
            });
        });
    }

    /**
     * This handles various phases of our location permission, and directs the user to various means to request the permission
     */
    const requestLocationPermission = () => {

        if (contextState.status == 'denied') {
            goToLocationSettings("application_details");
        }

        setPersistedState({
            ...contextState,
            status: 'requesting',
        });
        setHasRespondedToLocationPrompt(true);
        if (onResponseGiven) {
            onResponseGiven(true);
        }
        Geolocation.checkPermissions().then(result => {
            if (result.location == 'denied') {
                setPersistedState({
                    ...contextState,
                    status: 'denied',
                });
                goToLocationSettings("application_details");
            } else {
                setPersistedState({
                    ...contextState,
                    status: 'requesting',
                });
                requestLocation();
            }
        });
    }

    const modalDismissed = () => {
        setIsModalShowing(false);
        if (onResponseGiven) {
            onResponseGiven(false);
        }
    }

    const goToPrivacyPolicy = () => {
        if (privacyPolicyURL) {
            history.push(privacyPolicyURL);
        }
        modalDismissed();
    }

    // If the user has previously responded to the permission prompt,
    // and if we are in the initializing stage of our location,
    // then we want to try grabbing the location right away
    if (hasRespondedToLocationPrompt && contextState.status == 'initializing') {
        requestLocation();
    }

    // These statuses mean that we do not currently have full location access
    const locationNotAllowed = ['failed', 'in-settings', 'denied', 'requesting'].indexOf(contextState.status) != -1;


    const availableContexts = [];
    if (networkBasedLocation.status == 'success') {
        availableContexts.push(networkBasedLocation);
    }
    if (contextState.status == 'success') {
        availableContexts.push(contextState);
    }

    const readyContext = availableContexts
        .sort((a, b) => a.location.coords.accuracy - b.location.coords.accuracy)
        .shift();

    // contextState.location.coords = {
    //     latitude: 43.0389,
    //     longitude: -87.9065,
    //     accuracy: NaN,
    //     altitude: 188,
    //     altitudeAccuracy: NaN,
    //     heading: NaN,
    //     speed: NaN,
    // };
    const providingContext = readyContext ? readyContext : contextState;
    return (
        <GeoLocationContext.Provider value={providingContext}>
            <PermissionModal
                isOpen={isModalShowing}
                onDismissed={modalDismissed}
                onLocationGranted={requestLocationPermission}
                onPrivacyPolicyOpened={goToPrivacyPolicy}
            />
            {(hasRespondedToLocationPrompt && !locationNotAllowed && providingContext.location.coords || !locationRequired) ?
                <>{children}</> :
                (locationRequired && contextState.status == "denied" ?
                    <PermissionPrompt onLocationGranted={requestLocationPermission} onPrivacyPolicyOpened={goToPrivacyPolicy}/>:
                    <LoadingScreen text={"One Moment"}/>
                )
            }
        </GeoLocationContext.Provider>
    );
}

const NativeGeoLocationContextProviderWrapper : React.FC<GeoLocationContextProviderProps> = (props) => {

    return (
        <NetworkBasedLocationContextProvider wifiOnly locationRequired={false}>
            <NetworkBasedLocationContext.Consumer>
                {networkBasedLocation =>
                    <NativeGeoLocationContextProvider networkBasedLocation={networkBasedLocation} {...props}/>
                }
            </NetworkBasedLocationContext.Consumer>
        </NetworkBasedLocationContextProvider>
    );
}

export default NativeGeoLocationContextProviderWrapper;
