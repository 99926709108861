import React, {useEffect, useState} from 'react';
import './index.scss';
import {UserFollowsContextState} from '../../../contexts/UserFollowsContext';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import Follower, {findFollower, FollowerType, isFollowingEntity} from '../../../models/user/follower';
import {IonAlert, IonButton, IonIcon} from '@ionic/react';
import FollowerRequests from '../../../services/requests/FollowerRequests';
import User from '../../../models/user/user';
import Location from '../../../models/location/location';
import {BusinessLocationContext, BusinessLocationContextProvider} from '../../../contexts/BusinessLocationContext';
import Business from '../../../models/organization/business';
import UserFollowersService from '../../../services/users/UserFollowersService';
import {notifications, notificationsOutline} from 'ionicons/icons';
import {useLocation} from 'react-router';
import Category from "../../../models/category";

interface FollowButtonContentProps {
    user: User,
    follows: Follower[],
    relatedId: number,
    relatedType: FollowerType,
    related: Location|User|Category,
    removeFollower: (follower: Follower) => void,
    addFollower: (follower: Follower) => void,
    hideBell?: boolean,
    business?: Business,
}

const FollowButtonContent: React.FC<FollowButtonContentProps> = ({user, follows, relatedId, relatedType, related, removeFollower, addFollower, hideBell, business}) => {

    const [removeAllLocationsAlertShowing, setRemoveAllLocationsAlertShowing] = useState(false);
    const relatedLocationIds = business ? business.locations?.map(i => i.id) : null;
    const location = useLocation();
    const follower = findFollower(follows, relatedId, relatedType);

    const relatedBusinessFollowers = relatedLocationIds ? follows.filter(i => {
        return i.follows_type == 'location' && relatedLocationIds.indexOf(i.follows_id) !== -1;
    }) : [];

    const unFollow = (follow: Follower) => {
        removeFollower(follow);
        if (follow.id! > 0) {
            FollowerRequests.unFollow(follow).catch(console.error);
        }
    }

    const toggleFollowing = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        const follow = findFollower(follows, relatedId, relatedType);
        if (follow) {
            if (relatedBusinessFollowers.length > 1) {
                setRemoveAllLocationsAlertShowing(true);
            } else {
                unFollow(follow);
            }
        } else {
            startFollowing();
        }
    }

    const startFollowing = () => {
        const tempFollower = {
            id: Math.random() * -100,
            follows_type: relatedType,
            hidden: false,
            notify: true,
            follows_id: relatedId,
            user_id: user.id!,
            follows: related,
        };
        // This will cause the button to appear as being follows before the response
        addFollower(tempFollower);
        UserFollowersService.follow(user, related, relatedId, relatedType, follows, (newFollower: Follower) => {
            const tempFollower = findFollower(follows, newFollower.follows_id, newFollower.follows_type);
            if (tempFollower) {
                removeFollower(tempFollower);
            } else if (newFollower.follows_id == relatedId) {
                // If the temp follower does not exist after the follow response is done,
                // it means the user unfollows the item in between
                FollowerRequests.unFollow(newFollower).catch(console.error);
            }
            addFollower(newFollower)
        }, business);
    }

    const toggleNotify = (event: any, follower: Follower) => {
        event.preventDefault();
        event.stopPropagation();
        const newValue = !follower.notify;
        const newFollower = {
            ...follower,
            notify: newValue,
        }
        addFollower(newFollower);
        FollowerRequests.update(follower, {notify: newValue}).catch(console.error);
    }

    useEffect(() => {

        if (location.search.indexOf('follow') != -1 && !follower) {
            startFollowing();
        }
    }, [location.search])

    return (
        <React.Fragment>
            {(follower && !hideBell) &&
                <IonIcon
                    color={'primary'}
                    className={'notification-bell'}
                    icon={follower.notify ? notifications : notificationsOutline}
                    onClick={event => toggleNotify(event, follower)}
                />
            }
            <IonButton
                className={'follow-button ' + (follower ? 'following' : 'not-following')}
                color={'primary'}
                fill={follower ? 'outline' : 'default'}
                onClick={event => toggleFollowing(event)}
            >
                {follower ? 'Following' : 'Follow'}
            </IonButton>
            <IonAlert
                isOpen={removeAllLocationsAlertShowing}
                onDidDismiss={() => setRemoveAllLocationsAlertShowing(false)}
                message={'Do you want to stop following all locations for this business, or only this one?'}
                buttons={[
                    {
                        text: 'All',
                        handler: () => relatedBusinessFollowers.forEach(unFollow),
                    },
                    {
                        text: 'Only This One',
                        handler: () => {
                            const follower = findFollower(follows, relatedId, relatedType)
                            if (follower) {
                                unFollow(follower)
                            }
                        }
                    }
                ]}
            />
        </React.Fragment>
    );
}

interface FollowButtonProps {
    relatedId: number,
    relatedType: FollowerType,
    related: Location|User|Category,
    userFollowsContext: UserFollowsContextState,
    onBlack?: boolean,
    hideBell?: boolean,
    onFollow?: (follower: Follower) => void,
    onUnfollow?: () => void,
}

const FollowButton: React.FC<FollowButtonProps> = ({relatedId, relatedType, related, userFollowsContext, onBlack, onFollow, onUnfollow, hideBell}) => {

    const handleFollow = (follower: Follower, addFollower: (follower: Follower) => void) => {
        addFollower(follower)
        if (onFollow) {
            onFollow(follower)
        }
    }
    const handleUnfollow = (follower: Follower, removeFollower: (follower: Follower) => void) => {
        removeFollower(follower)
        if (onUnfollow) {
            onUnfollow()
        }
    }

    return (
        <div className={'follow-button-wrapper ' + (onBlack ? 'on-black' : '')} slot={"end"}>
            <MeContextProvider hideLoadingSpace>
                <MeContext.Consumer>
                    {meContext =>
                        relatedType == 'location' ?
                            <BusinessLocationContextProvider businessId={(related as Location).business_id} locationId={relatedId} hideLoadingSpace>
                                <BusinessLocationContext.Consumer>
                                    {businessLocationContext =>
                                        <FollowButtonContent
                                            user={meContext.me}
                                            relatedId={relatedId}
                                            relatedType={relatedType}
                                            business={businessLocationContext.business}
                                            related={related}
                                            follows={userFollowsContext.loadedData}
                                            removeFollower={follower => handleUnfollow(follower, userFollowsContext.removeModel)}
                                            addFollower={follower => handleFollow(follower, userFollowsContext.addModel)}
                                            hideBell={hideBell}
                                        />
                                    }
                                </BusinessLocationContext.Consumer>
                            </BusinessLocationContextProvider> :
                            relatedType == 'user' ?
                                ((related as User).allow_users_to_add_me ?
                                    <FollowButtonContent
                                        user={meContext.me}
                                        relatedId={relatedId}
                                        relatedType={relatedType}
                                        related={related}
                                        follows={userFollowsContext.loadedData}
                                        removeFollower={follower => handleUnfollow(follower, userFollowsContext.removeModel)}
                                        addFollower={follower => handleFollow(follower, userFollowsContext.addModel)}
                                        hideBell={hideBell}
                                    /> :
                                    // This is here for balance on the parent components
                                    <span/>
                                ) : <FollowButtonContent
                                    user={meContext.me}
                                    relatedId={relatedId}
                                    relatedType={relatedType}
                                    related={related}
                                    follows={userFollowsContext.loadedData}
                                    removeFollower={follower => handleUnfollow(follower, userFollowsContext.removeModel)}
                                    addFollower={follower => handleFollow(follower, userFollowsContext.addModel)}
                                    hideBell={true}
                                />
                    }
                </MeContext.Consumer>
            </MeContextProvider>
        </div>
    )
}

export default FollowButton;
