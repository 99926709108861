
const radius = 6371;
export const KM_IN_MILES = 1.60934;

/**
 * Calculates the distance in kilometers between two points of latitude and longitude
 *
 * @return float
 * @param lat1
 * @param long1
 * @param lat2
 * @param long2
 */
export function calculateDistance(lat1: number, long1: number, lat2: number, long2: number): number
{
    const lat1Radians = lat1 * Math.PI / 180;
    const lat2Radians = lat2 * Math.PI / 180;
    const latDifference = (lat2 - lat1) * Math.PI / 180;
    const longDifference = (long2 - long1) * Math.PI / 180;

    const haversine = Math.sin(latDifference / 2) * Math.sin(latDifference / 2) +
        Math.cos(lat1Radians) * Math.cos(lat2Radians) *
        Math.sin(longDifference / 2) * Math.sin(longDifference / 2);
    const crowFiles = 2 * Math.atan2(Math.sqrt(haversine), Math.sqrt(1 - haversine));

    return radius * crowFiles;
}

/**
 * Runs a quick conversion for us
 * @param km
 */
export function convertFromKMtoMiles(km: number): number {
    return km * 0.621371;
}



export const makeKMReadableAsMiles = (value: number): number => {
    return Math.round(value / KM_IN_MILES)
}