import React, {useState} from 'react'

import {
	IonContent,
	IonPage,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../components/LoggedInHeader';
import MeContextProvider, {MeContext} from '../../../contexts/MeContext';
import './index.scss';
import '@ionic/react/css/ionic-swiper.css';
import SavedPosts from '../../../components/MembersPages/SavedPosts';
import {UserCollectionsContext, UserCollectionsContextProvider} from "../../../contexts/UserCollectionsContext";
import AlbumList from "../../../components/Albums/AlbumList";


const NoAlbumsMessage: React.FC = () => (
	<div className={'no-albums-message'}>
		<h2>Create your first album</h2>
		<p>As you scroll through content, tap the bookmark icon to save your favorite posts to an album.</p>
	</div>
)

interface AlbumsContentProps {
	userId: number
	isEditing: boolean,
}

const AlbumsContent: React.FC<AlbumsContentProps> = ({userId, isEditing}) => {
	return (
		<UserCollectionsContextProvider userId={userId!}>
			<UserCollectionsContext.Consumer>
				{userCollectionsContext => (userCollectionsContext.loadedData.length ? (
					<AlbumList
						collectionContext={userCollectionsContext}
						inDeleteMode={isEditing}
					/>
				) : (
					<NoAlbumsMessage />
				))}
			</UserCollectionsContext.Consumer>
		</UserCollectionsContextProvider>
	)
}


const Albums: React.FC = ({}) => {

	const [isEditing, setIsEditing] = useState(false);

	return (
		<IonPage id={'albums-page'}>
			<LoggedInHeaderComponent
				hideBackButton={true}
				blackHeader={true}
				rightButton={{
					text: isEditing ? 'Done' : 'Edit',
					handler: () => setIsEditing(!isEditing)
				}}
			>
				Albums
			</LoggedInHeaderComponent>
			<IonContent>
				<MeContextProvider>
					<MeContext.Consumer>
						{meContext => (
							<AlbumsContent
								userId={meContext.me.id!}
								isEditing={isEditing}
							/>
						)}
					</MeContext.Consumer>
				</MeContextProvider>
			</IonContent>
		</IonPage>
	);
}

export default Albums;
