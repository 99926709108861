import {UserFollowsContextState} from "../../../contexts/UserFollowsContext";
import {SeenPostsContextConsumerState} from "../../../contexts/feed/SeenPostsContext";
import React, {useEffect, useRef, useState} from "react";
import Post from "../../../models/post/post";
import {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    RefresherEventDetail, ScrollDetail
} from "@ionic/react";
import {IonInfiniteScrollCustomEvent} from "@ionic/core/dist/types/components";
import {App} from "@capacitor/app";
import NewsFeedPost from "../NewsFeedPost";
import NoPosts from "../NoPosts";
import {SharedProps} from "../interfaces";
import "./index.scss"
import {connect} from "../../../data/connect";
import LoadingScreen from "../../LoadingScreen";

interface OwnProps extends SharedProps {
    userFollowsContext: UserFollowsContextState,
    seenPostsContext: SeenPostsContextConsumerState,
    onScroll: (isScrollDown: boolean) => void,
}

interface StateProps {
    hasSeenSavedTutorial: boolean
}

interface NewsFeedStateManagerProps extends OwnProps, StateProps {}

interface PostSize {
    hasViewed: boolean,
    height: number,
    // Once the scroll passes this, the post is in focus
    offsetTop: number,
    // Once the scroll passes this the first time, it has been viewed
    center: number,
    // Once the scroll passes this, it is out of focus
    offsetBottom: number,
}
interface PostSizes {[postId: number]: PostSize}

interface ScrollState {
    currentScroll: number,
    maxScroll: number,
}
const initialScrollState: ScrollState = {
    currentScroll: 0,
    maxScroll: 0,
}

function calculatePostSize(height: number, hasViewed: boolean, lastPostSize?: PostSize): PostSize {
    const offsetTop = (lastPostSize ? lastPostSize.offsetBottom : 0);
    return {
        hasViewed,
        height,
        offsetTop,
        center: offsetTop + (height / 2),
        offsetBottom: offsetTop + height,
    };
}

const NewsFeedStateManager: React.FC<NewsFeedStateManagerProps> = ({meContext, seenPostsContext, onPostSeen, hasSeenSavedTutorial, postsContext, selectLocation, skipPost, noPosts, children, inFocus, onScroll, ...rest}) => {

    const [displayingPosts, setDisplayingPosts] = useState(postsContext.loadedData);
    const [removedPosts, setRemovedPosts] = useState<Post[]>([])
    const [lastLoad, setLastLoad] = useState<number>(Date.now());

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [postSizes, setPostSizes] = useState<PostSizes>({})

    const [scrollState, setScrollState] = useState<ScrollState>(initialScrollState);

    const ionContent = useRef<HTMLIonContentElement>(null)

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setIsRefreshing(true)
        postsContext.refreshData(false).finally(() => {
            event.detail.complete();
        })
    }

    const loadNext = (event: IonInfiniteScrollCustomEvent<void>) => {
        postsContext.loadNext().finally(() => {
            event.target.complete();

        })
    }

    const removePost = (post: Post) => {
        setRemovedPosts([...removedPosts, post]);
        setDisplayingPosts([...displayingPosts].filter(i => i.id != post.id));
    }

    // TODO connect to scroll events
    const postSeen = (post: Post) => {
        onPostSeen(post)
    }

    const updatePostSize = (id: number, postSize: PostSize) => {
        postSizes[id] = postSize;
        setPostSizes({...postSizes})
    }

    const setPostSize = (post: Post, height: number) => {
        if (post.id) {
            const index = displayingPosts.indexOf(post)
            const previousCalculation = postSizes[post.id]
            const lastPostId = index > 0 ? displayingPosts[index - 1].id : undefined;
            const lastPostSize = lastPostId ? postSizes[lastPostId] : undefined;
            updatePostSize(post.id, calculatePostSize(height, previousCalculation?.hasViewed, lastPostSize));
        }
    }

    const setDisplayingPost = (post: Post) => {
        const newPosts = displayingPosts.map(i => i.id == post.id ? post : i);
        setDisplayingPosts([...newPosts])
    }

    const onScrollContent = (event: CustomEvent<ScrollDetail>) => {
        const currentScroll = event.detail.scrollTop;
        onScroll(currentScroll > scrollState.currentScroll && scrollState.currentScroll > 0)
        setScrollState({
            currentScroll,
            maxScroll: scrollState.maxScroll > currentScroll ?
                 scrollState.maxScroll : currentScroll,
        });
    }

    const getPostSize = (post: Post): PostSize|undefined => {
        return post.id ? postSizes[post.id] : undefined;
    }

    const hasViewedPost = (post: Post): boolean => {
        const postSize = getPostSize(post);
        if (postSize) {
            const windowHeight = ionContent.current?.offsetHeight ?? 0
            const hasViewed = postSize.hasViewed ?
                postSize.hasViewed : scrollState.maxScroll + windowHeight > postSize.center;
            if (!postSize.hasViewed && hasViewed) {
                updatePostSize(post.id!, {
                    ...postSize,
                    hasViewed: true,
                })
                postSeen(post)
            }

            return hasViewed;
        }
        return false;
    }

    const postInFocus = (post: Post): boolean => {
        const postSize = getPostSize(post);

        if (postSize && ionContent.current) {
            const height = ionContent.current.offsetHeight
            const topInView = scrollState.currentScroll + height >= postSize.offsetTop;
            const bottomInView = scrollState.currentScroll <= postSize.offsetBottom;
            return topInView && bottomInView
        }
        return false;
    }

    useEffect(() => {
        if (!postsContext.refreshing) {
            const removedIds = removedPosts.map(i => i.id)
            if (isRefreshing) {
                const newPosts = postsContext.loadedData
                    .filter(i => !removedIds.includes(i.id));
                setDisplayingPosts([...newPosts])
            } else {
                const displayingPostId = displayingPosts.map(i => i.id)
                const newPosts = postsContext.loadedData
                    .filter(i => !displayingPostId.includes(i.id))
                    .filter(i => !removedIds.includes(i.id));
                setDisplayingPosts([...displayingPosts, ...newPosts])
            }

            setIsRefreshing(false)
            setLastLoad(Date.now());
        }
    }, [postsContext.loadedData])

    useEffect(() => {

        App.addListener('appStateChange', ({ isActive }) => {
            if (Date.now() - lastLoad > 60 * 60 * 1000) {
                postsContext.refreshData(true).catch(console.error);
            }
        });
    }, [])

    return (
        <IonContent className={'news-feed'} onIonScroll={onScrollContent} scrollEvents ref={ionContent}>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
                <IonRefresherContent></IonRefresherContent>
            </IonRefresher>
            {(postsContext.noResults) ?
                <NoPosts {...noPosts}/> :
                !!displayingPosts.length ?
                    <React.Fragment>
                        {displayingPosts.map(post =>
                            <NewsFeedPost
                                key={post.id}
                                loggedInUser={meContext.me}
                                onPostRemoved={() => removePost(post)}
                                onPostMetaChanged={setDisplayingPost}
                                location={selectLocation(post.locations ?? [])}
                                post={post}
                                onHeightSet={height => setPostSize(post, height)}
                                inFocus={inFocus ? inFocus && postInFocus(post): false}
                                hasViewed={hasViewedPost(post)}
                                {...rest}
                            />
                        )}
                        <IonInfiniteScroll
                            onIonInfinite={loadNext}
                            threshold={'500px'}
                        >
                            <IonInfiniteScrollContent  loadingSpinner="bubbles"/>
                        </IonInfiniteScroll>
                    </React.Fragment> :
                    <LoadingScreen/>
            }
        </IonContent>
    )
}


export default connect<OwnProps, StateProps, { }>({
    mapStateToProps: (state) => ({
        hasSeenSavedTutorial: state.persistent.hasSeenSavedTutorial,
    }),
    component: NewsFeedStateManager
});
