import React, {useEffect, useState} from 'react';
import {IonAlert, IonContent, IonIcon, IonPage} from '@ionic/react';
import './index.scss';
import {OrganizationHeaderContext, OrganizationHeaderProps} from '../OrganizationHeader';
import {useHistory} from 'react-router';
import SideBySideComponent from '../../SideBySide';

interface BaseEditPageTemplateProps extends BaseEditPageTemplateWrapperProps {
    setHeaderProps: (props: OrganizationHeaderProps) => void,
}

const BaseEditPageTemplate: React.FC<BaseEditPageTemplateProps> = ({ title, setHeaderProps, iconUrl, runSave, onSaveComplete, customLayout, isDirty, onSave, editingData, children, ...rest}) => {

    const [alertShowing, setAlertShowing] = useState(false);
    const [done, setDone] = useState(false);

    const history = useHistory();
    const saveCurrentData = () => {
        setDone(true);
        onSave && onSave().then(() => {
            if (onSaveComplete) {
                onSaveComplete();
            }
            history.goBack();
            setTimeout(() => setDone(false), 500);
        });
    }

    useEffect(() => {
        if (runSave) {
            saveCurrentData();
        }
    }, [runSave]);

    const saveButton = onSave && {
        action: saveCurrentData,
        active: !!isDirty,
    }

    const backHandler = () => {
        if (!isDirty) {
            setDone(true);
            history.goBack();
        } else {
            setAlertShowing(true);
        }
    }

    useEffect(() => {
        if (!history.location.pathname.endsWith('editor') && !done) {
            setHeaderProps({
                children: title,
                saveButton: saveButton,
                backClicked: backHandler,
                closeButtonText: 'Done'
            })
        }
    }, [isDirty, title, editingData, onSave])

    const buttons = [
        {
            text: 'Discard',
            handler: history.goBack,
        },
    ]

    if (onSave) {
        buttons.push({
            text: 'Save',
            handler: saveCurrentData,
        });
    }

    return (
        <IonPage className={'base-location-edit-page'} {...rest}>
            <IonContent>
                {customLayout ? children :
                    <SideBySideComponent>
                        <IonIcon src={'/assets/app-icons/' + iconUrl + '.svg'}/>
                        <div className={'editable-content'}>
                            {children}
                        </div>
                    </SideBySideComponent>
                }
            </IonContent>
            <IonAlert
                isOpen={alertShowing}
                onDidDismiss={() => setAlertShowing(false)}
                header={'Discard Changes?'}
                message={'Would you like to save your changes, or discard them before going back?'}
                buttons={buttons}
            />
        </IonPage>
    )
}

interface BaseEditPageTemplateWrapperProps extends React.ComponentProps<typeof IonPage> {
    title: string
    iconUrl?: string
    customLayout?: boolean,
    isDirty?: boolean,
    runSave?: boolean,
    onSave?: () => Promise<any>,
    onSaveComplete?: () => void,
    editingData?: any,
}

const BaseEditPageTemplateWrapper: React.FC<BaseEditPageTemplateWrapperProps> = (props) => {

    return (
        <OrganizationHeaderContext.Consumer>
            {organizationHeaderContext =>
                <BaseEditPageTemplate
                    setHeaderProps={organizationHeaderContext.setSharedProps}
                    {...props}
                />
            }
        </OrganizationHeaderContext.Consumer>
    );
}

export default BaseEditPageTemplateWrapper;

