import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import Category from '../models/category';

/**
 * The state interface for our state
 */
export interface TopicsContextState extends BasePaginatedContextState<Category> {}

const defaultContext = {
    ...defaultBaseContext(),
    filter: {
        'featured': 1
    },
    limit: 100
} as TopicsContextState;

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

/**
 * The actual context component
 */
export const TopicsContext = React.createContext<TopicsContextState>(defaultContext);

export interface TopicsContextProviderProps extends BasePaginatedContextProviderProps{
}

export const TopicsContextProvider: React.FC<TopicsContextProviderProps> = (props => {
    const [industryState, setIndustriesState] = useState(persistentContext);

    useEffect(() => {
        prepareContextState(setIndustriesState, industryState, '/categories')
    }, []);

    return (
        <TopicsContext.Provider value={industryState}>
            <TopicsContext.Consumer>
                {context => {
                    /**
                     * set our persistent context whenever it is updated here
                     */
                    persistentContext = context;
                    return context.initialLoadComplete ? props.children : '';
                }}
            </TopicsContext.Consumer>
        </TopicsContext.Provider>
    )
});
