import React, {useEffect, useRef, useState} from 'react'
import {
	IonTabButton,
	IonRouterOutlet,
	IonTabBar,
	IonTabs,
	IonContent,
	IonModal,
	IonButton,
	IonPage, IonHeader, IonIcon,
} from '@ionic/react';
import './index.scss';
import {connect} from '../../../data/connect';
import {Redirect, useHistory} from 'react-router-dom';
import {Route} from 'react-router';
import Following from './Following';
import Nearby from './Nearby';
import {setHasSeenIntroductionTutorial} from '../../../data/persistent/persistent.actions';
import MeContextProvider, {MeContext, MeContextStateConsumer} from '../../../contexts/MeContext';
import NewsFeedSettings from "../../../components/NewsFeed/NewsFeedSettings";
import {add} from "ionicons/icons";
import Category from "./Category";

interface FeedTabsProps {
	meContext: MeContextStateConsumer
	onScroll: (isScrollDown: boolean) => void
}

const FeedTabs: React.FC<FeedTabsProps> = ({onScroll, meContext}) => {

	const history = useHistory();

	const goToTopics = () => {
		history.push('/home/dashboard/topics')
	}

	useEffect(() => {
		// Nested tabs break detecting the proper url sometimes,
		// so putting the redirect on a delay allows for the component to mount,
		// and receive the events it needs to as opposed to the parent tab component
		setTimeout(() => {
			if (window.location.pathname == "/home/dashboard/feed") {
				history.push('/home/dashboard/feed/nearby')
			}
		}, 5)
	}, []);

	return (
		<IonTabs className={'feed-tabs'}>
			<IonRouterOutlet>
				<Route path={`/home/dashboard/feed/nearby`} render={() => <Nearby onScroll={onScroll}/>}/>
				<Route path={`/home/dashboard/feed/following`} render={() => <Following onScroll={onScroll}/>}/>
				{meContext.me.followed_categories?.map(i => (
					<Route key={i.id} path={`/home/dashboard/feed/category/${i.id}`} render={() => <Category categoryId={i.id!} onScroll={onScroll}/>}/>
				))}
			</IonRouterOutlet>
			<IonTabBar slot="top" className={'feed-tabs-bar hide-in-desktop'} >
				<IonTabButton>
					<IonIcon onClick={goToTopics} icon={add}/>
				</IonTabButton>
				<IonTabButton tab='nearby' href={`/home/dashboard/feed/nearby`}>
					For you
				</IonTabButton>
				<IonTabButton tab='following' href={`/home/dashboard/feed/following`}>
					Following
				</IonTabButton>
				{meContext.me.followed_categories?.map(i => (
					<IonTabButton key={i.id} tab={`category-${i.id}`} href={`/home/dashboard/feed/category/${i.id}`}>
						{i.name}
					</IonTabButton>
				))}
			</IonTabBar>
		</IonTabs>
	)
}

interface StateProps {
	hasSeenIntroductionTutorial: boolean;
}

interface DispatchProps {
	setHasSeenIntroductionTutorial: typeof setHasSeenIntroductionTutorial,
}

interface FeedProps extends StateProps, DispatchProps {}

const FeedPage: React.FC<FeedProps> = ({setHasSeenIntroductionTutorial, hasSeenIntroductionTutorial, ...props}) => {
	const [settingsHidden, setSettingsHidden] = useState(false)

	const [introductionModalShowing, setIntroductionModalShowing] = useState(!hasSeenIntroductionTutorial);

	const introductionModalDismissed = () => {
		setHasSeenIntroductionTutorial(true);
		setIntroductionModalShowing(false);
	}

	const feedScrolled = (isScrollDown: boolean) => {
		if (settingsHidden !== isScrollDown) {
			setSettingsHidden(isScrollDown)
		}
	}
	return (
		<IonPage className={'feed-page' + (settingsHidden ? ' settings-hidden' : '')}>
			<MeContextProvider>
				<MeContext.Consumer>
					{meContext =>
						<IonContent className={'home-content'} id={'home'} scrollY={false}>
							<NewsFeedSettings isVisible={!settingsHidden}/>
							<IonRouterOutlet>
								<Route path={`/home/dashboard/feed`} render={() =>
									<FeedTabs meContext={meContext} onScroll={feedScrolled} {...props}/>
								}/>
								<Route path={`/home/dashboard/feed/*`} render={() =>
									<FeedTabs meContext={meContext} onScroll={feedScrolled} {...props}/>
								}/>
							</IonRouterOutlet>
							<IonModal
								className={'introduction-modal'}
								onDidDismiss={introductionModalDismissed}
								isOpen={introductionModalShowing}
							>
								<div className={'introduction-modal-content'}>
									<div className={'profile-name'}>
										{meContext.me.first_name.slice(0, 1) + " " + meContext.me.last_name.slice(0, 1)}
									</div>
									<h4>Time to find things to do</h4>
									<p>Get new ideas from local businesses and guides in the community around you!</p>
									<IonButton onClick={introductionModalDismissed}>Start scrolling</IonButton>
								</div>
							</IonModal>

						</IonContent>
					}
				</MeContext.Consumer>
			</MeContextProvider>
		</IonPage>
	);
}

export default connect<{ }, StateProps, {  }>({
	mapStateToProps: (state) => ({
		hasSeenIntroductionTutorial: state.persistent.hasSeenIntroductionTutorial,
	}),
	mapDispatchToProps: ({
		setHasSeenIntroductionTutorial,
	}),
	component: FeedPage
});
