import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Post from '../../models/post/post';
import {calculateDistance} from '../../util/location';
import LoadingScreen from '../../components/LoadingScreen';
import {connect} from '../../data/connect';
import {FeedSettings} from '../../data/persistent/persistent.state';
import User from '../../models/user/user';
import {BaseFeedContextState, BaseFeedStateProps, defaultFeedContext} from "./BaseFeedContext";
import {NearbyPostContextState, NearbyPostsContextProviderProps} from "./NearbyPostsContext";
import Coordinates from "../../models/coordinates";


const defaultContext = defaultFeedContext();

/**
 * The actual context component
 */
export const NearbyCategoryPostsContext = React.createContext<NearbyPostContextState>(defaultContext);


interface OwnProps extends Coordinates, BasePaginatedContextProviderProps {
    categoryId: number
}

export interface NearbyCategoryPostsContextProviderProps extends OwnProps, NearbyPostsContextProviderProps {}

let lastLoad: Coordinates|undefined = undefined


const NearbyCategoryPostsContextProvider: React.FC<NearbyCategoryPostsContextProviderProps> = (({latitude, longitude, categoryId, feedSettings, ...props}) => {
    const [postsState, setPostsState] = useState<NearbyPostContextState>({
        ...defaultContext,
        filter: {category: categoryId},
        initialLoadComplete: false,
    });

    const needsUpdate = (newLat: number, newLon: number): boolean => {
        if (lastLoad) {
            return calculateDistance(lastLoad.latitude, lastLoad.longitude, newLat, newLon) > .5;
        }

        return true;
    }

    const initiateLoad = (loadLatitude: number, loadLongitude: number) => {

        postsState.initiated = true;
        const newContext = prepareContextState(setPostsState, postsState,'/nearby-posts', {
            latitude: loadLatitude,
            longitude: loadLongitude,
            radius: feedSettings.radius,
        });
        newContext.initialLoadComplete = false;
        setPostsState({...newContext});
        newContext.refreshData(false)
        lastLoad = {
            latitude: loadLatitude,
            longitude: loadLongitude,
        }
    }

    useEffect(() => {
        if (latitude && longitude && (!postsState.initialLoadComplete || needsUpdate(latitude, longitude))) {
            initiateLoad(latitude, longitude)
        }
    }, [latitude, longitude]);

    useEffect(() => {
        if (latitude && longitude) {
            initiateLoad(latitude, longitude);
        }
    }, [feedSettings])

    const context = createCallbacks(setPostsState, postsState,'/nearby-posts');

    return (
        <NearbyCategoryPostsContext.Provider value={context}>
            {(context.initialLoadComplete || context.refreshing) ? props.children : <LoadingScreen/>}
        </NearbyCategoryPostsContext.Provider>
    )
});

export default connect<OwnProps, BaseFeedStateProps, {}>({
    mapStateToProps: (state) => ({
        feedSettings: state.persistent.feedSettings
    }),
    component: NearbyCategoryPostsContextProvider
});
