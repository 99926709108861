import React, {useState} from 'react';
import './index.scss';
import {IonActionSheet, IonButton, IonIcon} from "@ionic/react";
import ReactDOM from "react-dom";
import {notifications} from "ionicons/icons";
import moment from "moment";
import {da} from "date-fns/locale";

interface NotificationScheduleModalContentProps {
    onCancel: () => void,
    onSave: (date: string) => void,
    postExpirationDate?: string,
    currentNotificationDate?: string,
}

const NotificationScheduleModalContent: React.FC<NotificationScheduleModalContentProps> = ({onCancel, onSave, postExpirationDate, currentNotificationDate}) => {

    const calculateDefaultNotificationDay = () => {
        const now = moment()
        let futureDate = moment();
        if (currentNotificationDate) {
            futureDate = moment(currentNotificationDate)
        } else if (postExpirationDate) {
            futureDate = moment(postExpirationDate)
        } else {
            futureDate.add(4, "days")
        }

        return Math.ceil(futureDate.diff(now, "days", true))
    }

    const determineAvailableOptions = (): number[] => {
        if (postExpirationDate) {
            const now = moment()
            const expirationDate = moment(currentNotificationDate)
            let daysInFuture = Math.floor(expirationDate.diff(now, "day", true));
            daysInFuture = daysInFuture > 1 ? daysInFuture : 1;

            let availableOptions = []

            for (let i = 1; i < daysInFuture; i++){
                availableOptions.push(i)
            }

            return availableOptions;
        }

        return [2, 3, 4, 5, 6];
    }

    const [optionsShowing, setOptionsShowing] = useState(false)
    const [notificationDays, setNotificationDays] = useState(calculateDefaultNotificationDay())
    const [availableOptions, _] = useState(determineAvailableOptions())

    const save = () => {
        const now = moment()
        now.add(notificationDays, "days")
        onSave(now.format())
    }

    return (
        <div className={'notification-schedule-modal-content'}>
            <div className={'actions'}>
                <IonButton fill={"clear"} onClick={() => onCancel()}>Cancel</IonButton>
                <IonButton className={'btn-add'} fill={"clear"} onClick={() => save()}>Add</IonButton>
            </div>
            <div className={'notification-details'}>
                <IonIcon
                    icon={notifications}
                />
                <p>We'll send a reminder in </p>
                <p className={'show-options-btn'} onClick={() => setOptionsShowing(true)}>
                    {notificationDays}
                </p>
                <p>days</p>
            </div>
            {optionsShowing &&
                <ul className={'available-options'}>
                    {availableOptions.map(option =>
                        <li
                            onClick={() => setNotificationDays(option)}
                            className={option == notificationDays ? 'selected' : ''}
                        >
                            {option}
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

const buildContent = (props: NotificationScheduleModalContentProps) => {
    const actionSheetGroup = document.getElementById('user-selector-action-sheet')?.querySelector('.action-sheet-group') as HTMLElement;

    ReactDOM.render(
        <NotificationScheduleModalContent
            {...props}
        />
        , actionSheetGroup);
}

interface NotificationScheduleModalProps extends NotificationScheduleModalContentProps {
    isOpen: boolean,
    onCancel: () => void,
    onSave: (date: string) => void,
    postExpirationDate?: string,
}

const NotificationScheduleModal: React.FC<NotificationScheduleModalProps> = ({isOpen, ...props}) => {

    return (
        <IonActionSheet
            id={'user-selector-action-sheet'}
            isOpen={isOpen}
            onWillPresent={() => buildContent(props)}
            onDidDismiss={() => props.onCancel()}
        />
    )
}

export default NotificationScheduleModal;