import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from '../BasePaginatedContext';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Post from '../../models/post/post';
import {calculateDistance} from '../../util/location';
import LoadingScreen from '../../components/LoadingScreen';
import {connect} from '../../data/connect';
import {FeedSettings} from '../../data/persistent/persistent.state';
import User from '../../models/user/user';
import {BaseFeedContextState, BaseFeedStateProps, defaultFeedContext} from "./BaseFeedContext";
import Coordinates from "../../models/coordinates";

/**
 * The state interface for our state
 */
export interface NearbyPostContextState extends BaseFeedContextState {}

const defaultContext = defaultFeedContext();

/**
 * This lets us persist the loaded state across multiple instances of the provider
 */
let persistentContext = defaultContext;

let nearbyPostsSubscriptions: {[key: string]: Dispatch<SetStateAction<NearbyPostContextState>>} = {};


/**
 * The actual context component
 */
export const NearbyPostsContext = React.createContext<NearbyPostContextState>(defaultContext);


interface OwnProps extends Coordinates, BasePaginatedContextProviderProps {}

export interface NearbyPostsContextProviderProps extends OwnProps, BaseFeedStateProps {}

let lastLoad: Coordinates|undefined = undefined


const NearbyPostsContextProvider: React.FC<NearbyPostsContextProviderProps> = (({latitude, longitude, feedSettings, ...props}) => {
    const [postsState, setPostsState] = useState({
        ...persistentContext,
        initialLoadComplete: false,
    });
    const [instanceKey, _] = useState(Math.random() + "-" + Date.now());


    const setPersistedState = (newState: NearbyPostContextState) => {
        persistentContext = newState;
        Object.keys(nearbyPostsSubscriptions).forEach(key => {
            if (key != instanceKey) {
                nearbyPostsSubscriptions[key](persistentContext)
            }
        });
    }

    useEffect(() => {
        if (postsState != persistentContext) {
            setPersistedState(postsState)
        }
    }, [postsState])

    useEffect(() => {
        nearbyPostsSubscriptions[instanceKey] = setPostsState;

        return () => {
            delete nearbyPostsSubscriptions[instanceKey];
        }
    }, []);

    const needsUpdate = (newLat: number, newLon: number): boolean => {
        if (lastLoad) {
            return calculateDistance(lastLoad.latitude, lastLoad.longitude, newLat, newLon) > .5;
        }

        return true;
    }

    const initiateLoad = (loadLatitude: number, loadLongitude: number) => {

        postsState.initiated = true;
        const newContext = prepareContextState(setPostsState, postsState,'/nearby-posts', {
            latitude: loadLatitude,
            longitude: loadLongitude,
            radius: feedSettings.radius,
        });

        if (!feedSettings.showBusinesses || !feedSettings.showMembers) {
            newContext.setFilter('publisher_type', feedSettings.showMembers ? 'user' : 'organization');
        } else {
            newContext.setFilter('publisher_type', undefined);
        }
        setPostsState({...newContext});
        newContext.refreshData(false);
        lastLoad = {
            latitude: loadLatitude,
            longitude: loadLongitude,
        }
    }

    useEffect(() => {
        if (latitude && longitude && (!postsState.initialLoadComplete || needsUpdate(latitude, longitude))) {
            initiateLoad(latitude, longitude)
        }
    }, [latitude, longitude]);

    useEffect(() => {
        if (latitude && longitude) {
            initiateLoad(latitude, longitude);
        }
    }, [feedSettings])

    const context = createCallbacks(setPostsState, postsState,'/nearby-posts');
    return (
        <NearbyPostsContext.Provider value={context}>
            {(context.initialLoadComplete) ? props.children : <LoadingScreen text={"Loading"}/>}
        </NearbyPostsContext.Provider>
    )
});

export default connect<OwnProps, BaseFeedStateProps, {}>({
    mapStateToProps: (state) => ({
        feedSettings: state.persistent.feedSettings
    }),
    component: NearbyPostsContextProvider
});
