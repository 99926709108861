import React, {useEffect, useState} from 'react'
import {
	IonContent,
	IonInfiniteScroll, IonInfiniteScrollContent,
	IonPage,
} from '@ionic/react';
import LoggedInHeaderComponent from '../../../../components/LoggedInHeader';
import './index.scss';
import {useParams} from "react-router";
import {CategoryContext, CategoryContextProvider} from "../../../../contexts/CategoryContext";
import Category from "../../../../models/category";
import MeContextProvider, {MeContext, MeContextStateConsumer} from "../../../../contexts/MeContext";
import {UserFollowsContext, UserFollowsContextProvider} from "../../../../contexts/UserFollowsContext";
import FollowButton from "../../../../components/MembersPages/FollowButton";
import GeoLocationContextProvider, {GeoLocationContext} from "../../../../components/GeoLocationContextProvider";
import NearbyCategoryPostsContextProvider, {NearbyCategoryPostsContext} from "../../../../contexts/feed/NearbyCategoryPostsContext";
import {BaseFeedContextState} from "../../../../contexts/feed/BaseFeedContext";
import {IonInfiniteScrollCustomEvent} from "@ionic/core/dist/types/components";
import {App} from "@capacitor/app";
import PostItemWithLocation from "../../../../components/MembersPages/PostItemWithLocation";
import {filterUnique} from "../../../../models/base-model";

interface CategoryContentProps {
	category: Category
}

const CategorySummary: React.FC<CategoryContentProps> = ({category}) => {
	const follower_label = category?.followers_count !== 1 ? ' Followers' : ' Follower'
	const post_label = category?.posts_count !== 1 ? ' Posts' : ' Post'

	const toggleFollowing = (meContext: MeContextStateConsumer, following: boolean) => {
		let categories = meContext.me.followed_categories ?? [];

		if (following) {
			categories.push(category)
		} else if (!following) {
			categories = categories.filter(i => i.id != category.id)
		}
		meContext.setMe({
			...meContext.me,
			followed_categories: [...filterUnique(categories)],
		})
	}

	return (
		<div className={"topic-summary"}>
			<h2>{category.name}</h2>
			<div className={"category-data"}>
				<p>{(category?.followers_count ?? '0') + follower_label}</p>
				<p>{(category?.posts_count ?? '0') + post_label}</p>
			</div>
			<MeContextProvider optional hideLoadingSpace>
				<MeContext.Consumer>
					{meContext =>
						<UserFollowsContextProvider userId={meContext.me.id!}>
							<UserFollowsContext.Consumer>
								{userFollowsContext =>
									<FollowButton
										relatedId={category.id!}
										relatedType={'category'}
										related={category}
										userFollowsContext={userFollowsContext}
										onFollow={() => toggleFollowing(meContext, true)}
										onUnfollow={() => toggleFollowing(meContext, false)}
									/>
								}
							</UserFollowsContext.Consumer>
						</UserFollowsContextProvider>
					}
				</MeContext.Consumer>
			</MeContextProvider>
		</div>
	)
}

const NoCategoryPosts: React.FC = () => (
	<h5>No Posts available in your area at this time.</h5>
)

interface CategoryPostsResults {
	nearbyCategoryPostContext:  BaseFeedContextState,
}
const CategoryPostsResults: React.FC<CategoryPostsResults> = ({nearbyCategoryPostContext}) => {

	const filterAvailablePosts = () => nearbyCategoryPostContext.loadedData.filter(i => i.publisher_type == "organization")

	const [displayingPosts, setDisplayingPosts] = useState(filterAvailablePosts());
	const [lastLoad, setLastLoad] = useState<number>(Date.now());

	const loadNext = (event: IonInfiniteScrollCustomEvent<void>) => {
		nearbyCategoryPostContext.loadNext().finally(() => {
			event.target.complete();

		})
	}

	useEffect(() => {
		if (!nearbyCategoryPostContext.refreshing) {
			const displayingPostId = displayingPosts.map(i => i.id)
			const newPosts = filterAvailablePosts()
				.filter(i => !displayingPostId.includes(i.id))
			setDisplayingPosts([...displayingPosts, ...newPosts])

			setLastLoad(Date.now());
		}
	}, [nearbyCategoryPostContext.loadedData])

	useEffect(() => {

		App.addListener('appStateChange', ({ isActive }) => {
			if (Date.now() - lastLoad > 60 * 60 * 1000) {
				nearbyCategoryPostContext.refreshData(true).catch(console.error);
			}
		});
	}, [])

	return (
		<React.Fragment>
			{!!displayingPosts.length ?
				<React.Fragment>
					<h4>Recommended Posts</h4>
					{displayingPosts.map( (post) => (
						<PostItemWithLocation
							key={post.id}
							post={post}
							lines={'none'}
							baseUrl={`/home/dashboard`}
						/>
					))}
					<IonInfiniteScroll
						onIonInfinite={loadNext}
						threshold={'500px'}
					>
						<IonInfiniteScrollContent  loadingSpinner="bubbles"/>
					</IonInfiniteScroll>
				</React.Fragment> :
				<React.Fragment/>
			}
		</React.Fragment>
	)
}

const CategoryPosts: React.FC<CategoryContentProps> = ({category}) => {
	const [forceLocationRefresh, setForceLocationRefresh] = useState(false);
	return (
		<GeoLocationContextProvider
			privacyPolicyURL={'/home/dashboard/account/privacy-policy'}
			locationRequired
			forceRefresh={forceLocationRefresh}
			onLocationReceived={() => setForceLocationRefresh(false)}
		>
			<GeoLocationContext.Consumer>
				{geoLocationContext => category.id ? (
					<IonContent className={'topic-list'}>
						<NearbyCategoryPostsContextProvider
							categoryId={category.id}
							latitude={geoLocationContext.location.coords.latitude}
							longitude={geoLocationContext.location.coords.longitude}>
							<NearbyCategoryPostsContext.Consumer>

								{nearbyCategoryPostContext => (nearbyCategoryPostContext.noResults ?
									<NoCategoryPosts />:
									<CategoryPostsResults
										nearbyCategoryPostContext={nearbyCategoryPostContext}
									/>
								)}
							</NearbyCategoryPostsContext.Consumer>
						</NearbyCategoryPostsContextProvider>
					</IonContent>
				) : null}
			</GeoLocationContext.Consumer>
		</GeoLocationContextProvider>
	)
}

const CategoryContent: React.FC<CategoryContentProps> = ({category}) => (
	<React.Fragment>
		<CategorySummary category={category}/>
		<CategoryPosts category={category}/>
	</React.Fragment>
)

type RouteParams = {
	topicId: string,
}
const Topic: React.FC = () => {
	const {topicId} = useParams<RouteParams>();
	// JSON.stringify(CategoryRequests.retrieveCategoryById(Number(topicId)))
	return (
		<IonPage className={'topic-page'}>
			<LoggedInHeaderComponent>
				Topic
			</LoggedInHeaderComponent>
			<IonContent scrollY={false} className={'ion-no-padding main-content'}>
				<IonContent className={'results-wrapper'}>
					<CategoryContextProvider categoryId={parseInt(topicId!)}>
						<CategoryContext.Consumer>
							{categoryContext =>
								<CategoryContent category={categoryContext.category} />
							}
						</CategoryContext.Consumer>
					</CategoryContextProvider>
				</IonContent>
			</IonContent>
		</IonPage>
	)
}

export default Topic

