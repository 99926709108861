import React from 'react';
import './index.scss';
import {PostContext, PostContextProvider} from "../../../contexts/PostContext";
import Collection from "../../../models/user/collection";

const AlbumPostImagePlaceholder: React.FC = () => (
    <div className={'album-post-thumbnail-placeholder'}></div>
)
interface AlbumPostImageProps {
    imageUrl?: string
    noBorder?: boolean
}

const AlbumPostImage: React.FC<AlbumPostImageProps> = ({imageUrl, noBorder}) => {
    return (
        imageUrl ?
            <div
                className={'album-post-thumbnail ' + (noBorder ? 'no-border' : ' ') }
                style={{backgroundImage: `url(${imageUrl})`}}
            /> : <AlbumPostImagePlaceholder />
    )
}

export default AlbumPostImage
