import React from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar} from '@ionic/react';
import "./index.scss";

export interface PermissionPromptProps {
    onLocationGranted: () => void,
    onPrivacyPolicyOpened: () => void,
}

const PermissionModal: React.FC<PermissionPromptProps> = ({onLocationGranted, onPrivacyPolicyOpened}) => {

    return (
        <IonContent className={'permission-prompt'} scrollY={false}>
            <div className={'location-icon'}>
                <IonIcon src={'/assets/app-icons/location-map.svg'}/>
            </div>
            <h4>Enable Location</h4>
            <p className={'sub-header'}>The GEDDIT LOCAL app needs your location to show you all the great local things going on around you.</p>
            <IonButton onClick={onLocationGranted}>Allow Location</IonButton>
            <p className={'disclaimer'}>Your location is used only to provide the best user experience. it is not stored or collected anywhere. Read more in our <a onClick={onPrivacyPolicyOpened}>Privacy Policy</a> </p>
        </IonContent>
    )
}

export default PermissionModal;
