import React from 'react';
import Post from '../../../models/post/post';
import './index.scss';
import FetchGooglePlaceDetailsContextProvider, {
    FetchGooglePlaceDetailsContext
} from '../../Providers/GooglePlaces/FetchGooglePlaceDetailsContextProvider';
import PostLocation, {findGooglePlaceAddressComponent} from '../../../models/post/post-location';
import GoogleLocalityDisplay from '../../GoogleLocalityDisplay';
import ProfileImage from "../../ProfileImage";
import moment from "moment";
import {formatTimeAgo} from "../../../util/time";
import {useHistory} from 'react-router-dom';

interface PostLikeLinkProps {
    post: Post,
}

const PostLikeLink: React.FC<PostLikeLinkProps> = ({post}) => {

    const navigate = useHistory();

    const viewLikes = (event: any) => {
        event.preventDefault()
        event.stopPropagation();
        navigate.push('/home/dashboard/post/' + post.id + '/likes');
    }

    return (post.like_count > 0 ?
        <p className={'like-count'} onClick={viewLikes}>{post.like_count} <span>Like{post.like_count !== 1 ? 's': ''}</span></p> :
        <p className={'like-count'}>&nbsp;</p>
    )
}

export default PostLikeLink;
