import React from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar} from '@ionic/react';
import "./index.scss";
import PermissionPrompt, {PermissionPromptProps} from "../PermissionPrompt";

interface PermissionModalProps extends PermissionPromptProps {
    isOpen: boolean,
    onDismissed: () => void,
}

const PermissionModal: React.FC<PermissionModalProps> = ({isOpen, onDismissed, ...rest}) => {

    return (
        <IonModal
            isOpen={isOpen}
            className={'permission-modal'}
        >
            <IonHeader className={'permission-prompt-header'}>
                <IonToolbar>
                    <IonButtons slot={'start'}>
                        <a onClick={onDismissed}>X</a>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <PermissionPrompt {...rest}/>
        </IonModal>
    )
}

export default PermissionModal;
