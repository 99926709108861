import React from 'react';
import './index.scss';
import Category from "../../models/category";
import {IonItem, IonList} from "@ionic/react";
import MeContextProvider, {MeContext, MeContextStateConsumer} from "../../contexts/MeContext";
import {UserFollowsContext, UserFollowsContextProvider} from "../../contexts/UserFollowsContext";
import FollowButton from "../MembersPages/FollowButton";
import {filterUnique} from "../../models/base-model";
import meContext from "../../contexts/MeContext";


interface TopicsListItemProps {
    topic: Category;
}

const TopicsListItem: React.FC<TopicsListItemProps> = ({topic}) => {

    const addUserCategory = (meContext: MeContextStateConsumer, category: Category) => {
        const newCategories = [...(meContext.me.followed_categories ?? []), category]
        meContext.setMe({
            ...meContext.me,
            followed_categories: filterUnique(newCategories),
        })
    }

    const removeUserCategory = (meContext: MeContextStateConsumer, category: Category) => {
        const newCategories =
            [...(meContext.me.followed_categories ?? [])].filter(i => i.id != category.id)
        meContext.setMe({
            ...meContext.me,
            followed_categories: newCategories,
        })
    }

    return (
        <IonItem className={'topic-item'} lines={'none'} routerLink={'/home/dashboard/topic/' + topic.id} detail={false}>
            <div className={'four-square'}>
                <div></div>
                <div></div>
            </div>
            {topic.name}
            <MeContextProvider optional hideLoadingSpace>
                <MeContext.Consumer>
                    {meContext =>
                        <UserFollowsContextProvider userId={meContext.me.id!}>
                            <UserFollowsContext.Consumer>
                                {userFollowsContext =>
                                    <FollowButton
                                        relatedId={topic.id!}
                                        relatedType={'category'}
                                        related={topic}
                                        userFollowsContext={userFollowsContext}
                                        onFollow={() => addUserCategory(meContext, topic)}
                                        onUnfollow={() => removeUserCategory(meContext, topic)}
                                    />
                                }
                            </UserFollowsContext.Consumer>
                        </UserFollowsContextProvider>
                    }
                </MeContext.Consumer>
            </MeContextProvider>
        </IonItem>
    )
}

interface TopicsListProps {
    topics: Category[];
}
const TopicsList: React.FC<TopicsListProps> = ({topics}) => (
    <IonList className={'topics-list'}>
        {topics.map(topic => <TopicsListItem topic={topic} />)}
    </IonList>
)

export default TopicsList;
