import React from "react";
import {IonIcon} from "@ionic/react";
import "./index.scss"

export interface NoPostsProps {
    headline?: string,
    copy?: string,
    icon?: string,
}

const NoPosts: React.FC<NoPostsProps> = ({icon, headline, copy}) => {
    return (
        <div className={'no-posts-wrapper'}>
            <div className={'no-posts-details'}>
                <IonIcon src={icon ?? '/assets/app-icons/browse.svg'}/>
                <h4>{headline ?? "You're all caught up!" }</h4>
                <p>{copy ?? 'There are no more updates at this time. Check back soon!'}</p>
            </div>
        </div>
    )
}

export default NoPosts