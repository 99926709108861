import {
    IonInput,
    IonItem,
    IonCheckbox
} from '@ionic/react'
import React, {useRef, useState} from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import './index.scss'
import {RequestError} from '../../../../models/request-error';
import ServerAlert from '../../../../components/ServerAlert';
import SignUpPageStep, {FormInformation} from "../../../../components/WelcomeSection/SignUpPageStep";
import {useHistory} from 'react-router-dom';


const EmailStep: React.FC = () => {
    const [form, setForm] = useState<any>(undefined);
    const history = useHistory();
    const emailInputRef = useRef<HTMLIonInputElement>(null)
    const SignUpSchema = Yup.object().shape({
        email: Yup.string().email('Invalid Email').required('Email is required'),
    })

    const validateForm = (submission: any) : string|undefined =>  {
        if (submission.errors.email && submission.touched.email) {
            return submission.errors.email
        }
        return undefined
    }

    const formInformation: FormInformation = {
        onValidate: validateForm,
        validationSchema: SignUpSchema,
        setForm,
    }

    const onNextStep = () => {
        history.push('/welcome/sign-up/date-of-birth');
    }

    return (
        <SignUpPageStep
            cancelOnBack
            header={'Add your home email'}
            formInformation={formInformation}
            onNextStep={onNextStep}
            className={'email-signup-step'}
            inputs={emailInputRef.current ? [emailInputRef.current] : undefined}
        >
            {form &&
                <div>
                    <IonItem className={'email-box'}>
                        <IonInput
                            name='email'
                            placeholder={'email@example.com'}
                            type='email'
                            onIonChange={form.handleChange}
                            ref={emailInputRef}
                        />
                    </IonItem>
                </div>
            }
        </SignUpPageStep>
    )
}

export default EmailStep;
