import {
    BasePaginatedContextProviderProps,
    BasePaginatedContextState, createCallbacks,
    defaultBaseContext, prepareContextState,
} from './BasePaginatedContext';
import React, {useEffect, useState} from 'react';
import CollectionItem from "../models/user/collection-items";
import Post from "../models/post/post";

/**
 * The state interface for our state
 */
export interface CollectionItemsContextState extends BasePaginatedContextState<CollectionItem> {
    collectionId?: number,
    isInCollection: (post: Post) => boolean,
    removePost: (post: Post) => boolean,
    keepPost: (post: Post) => void,
}

let lastCollectionId: number|undefined = undefined
let isInCollection = (post: Post) => false
let removePost = (post: Post) => false
let keepPost = (post: Post) => {}

function createDefaultState(): CollectionItemsContextState {
    return {
        ...defaultBaseContext(),
        expands: [
            'item',
            'item.locations',
            'item.postLocations',
            'item.publisher',
        ],
        filter: {
            "removed_at": "null"
        },
        loadAll: true,
        order: {
            'id': 'desc',
        },
        limit: 20,
        collectionId: lastCollectionId,
        isInCollection: isInCollection,
        removePost: removePost,
        keepPost: keepPost,
    }
}

let cachedContext = createDefaultState();

/**
 * The actual context component
 */
export const CollectionItemsContext = React.createContext<CollectionItemsContextState>(createDefaultState());

export interface CollectionItemsContextProviderProps extends BasePaginatedContextProviderProps{
    collectionId?: number
}

export const CollectionItemsContextProvider: React.FC<CollectionItemsContextProviderProps> = ({collectionId, children, ...rest}) => {
    const [collectionItemsState, setCollectionItemsState] = useState<CollectionItemsContextState>(cachedContext);

    const matchingPost = (post: Post, i: CollectionItem) => i.item_id == post.id && i.item_type == "post"

    useEffect(() => {
        // TODO update to mark post as removed
        isInCollection = (post: Post ) => {
            return !!collectionItemsState.loadedData
                .find(i => matchingPost(post, i))
        }
        removePost = (post: Post) => {
            let found = false;
            const updateItems = collectionItemsState.loadedData
                .map(i =>  {
                    if (matchingPost(post, i)) {
                        found = true;
                        i.removed_at = new Date().toISOString();
                    }
                    return i;
                })
            setCollectionItemsState({
                ...collectionItemsState,
                loadedData: updateItems
            })

            return found
        };
        keepPost = (post: Post) => {
            const updateItems = collectionItemsState.loadedData
                .map(i =>  {
                    if (matchingPost(post, i)) {
                        i.removed_at = undefined;
                    }
                    return i;
                })
            setCollectionItemsState({
                ...collectionItemsState,
                loadedData: updateItems
            })
        };
        setCollectionItemsState({
            ...collectionItemsState,
            isInCollection,
            removePost,
            keepPost
        })
    }, [collectionItemsState.loadedData]);

    useEffect(() => {
        if (collectionId) {
            lastCollectionId = collectionId;
            const newState = {
                ...createDefaultState(),
            }
            const collectionItemsNewContextState = prepareContextState(setCollectionItemsState as any, newState, '/collections/' + collectionId + '/items')
            setCollectionItemsState({
                ...collectionItemsNewContextState,
                isInCollection,
                removePost,
                keepPost,
            })
        }
    }, [collectionId]);

    cachedContext = {
        ...collectionItemsState,
        ...createCallbacks(setCollectionItemsState as any, collectionItemsState, '/collections/' + collectionId + '/items'),
    }

    return (
        <CollectionItemsContext.Provider value={cachedContext}>
            {children}
        </CollectionItemsContext.Provider>
    )
};
