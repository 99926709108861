import React from 'react'
// @ts-ignore
import Post, {isPostRecentlyPublished} from '../../models/post/post';
import {
	UserFollowsContext,
	UserFollowsContextProvider,
} from '../../contexts/UserFollowsContext';
import SeenPostsContextProvider, {
	SeenPostsContext,
} from '../../contexts/feed/SeenPostsContext';
import {SharedProps} from "./interfaces";
import NewsFeedStateManager from "./NewsFeedStateManager";

interface NewsFeedProps extends SharedProps {
	onScroll: (isScrollDown: boolean) => void
}

const NewsFeed: React.FC<NewsFeedProps> = ({meContext, ...rest}) => {

	return (
		<UserFollowsContextProvider userId={meContext.me.id!}>
			<UserFollowsContext.Consumer>
				{userFollowsContextConsumer =>
					<SeenPostsContextProvider>
						<SeenPostsContext.Consumer>
							{seenPostsContext => (
								<NewsFeedStateManager
									meContext={meContext}
									seenPostsContext={seenPostsContext}
									userFollowsContext={userFollowsContextConsumer}
									{...rest}
								/>
							)}
						</SeenPostsContext.Consumer>
					</SeenPostsContextProvider>
				}
			</UserFollowsContext.Consumer>
		</UserFollowsContextProvider>
	)
}

export default NewsFeed;
