import React, {useEffect, useState} from 'react'
import NewsFeed from '../../../../components/NewsFeed';
import './index.scss';
import GeoLocationContextProvider, {GeoLocationContext} from '../../../../components/GeoLocationContextProvider';
import NearbyPostsContextProvider, {NearbyPostsContext} from '../../../../contexts/feed/NearbyPostsContext';
import Location from '../../../../models/location/location';
import {calculateDistance} from '../../../../util/location';
import {IonPage} from '@ionic/react';
import {App} from '@capacitor/app';
import {useHistory} from 'react-router';
import MeContextProvider, {MeContext} from '../../../../contexts/MeContext';
interface NearbyProps {
	onScroll: (isScrollDown: boolean) => void
}
const Nearby: React.FC<NearbyProps> = ({onScroll}) => {

	const history = useHistory();
	const [inFocus, setInFocus] = useState(false);

	const [forceLocationRefresh, setForceLocationRefresh] = useState(false);

	const findClosestLocation = (latitude: number, longitude: number, locations: Location[]) : Location|undefined => {
		return locations.sort((a, b) => {
			return calculateDistance(latitude, longitude, a.latitude!, a.longitude!) -
				calculateDistance(latitude, longitude, b.latitude!, b.longitude!)
		})[0] ?? undefined;
	}

	useEffect(() => {
		App.addListener('appStateChange', ({ isActive }) => {
			if (isActive) {
				setForceLocationRefresh(true);
			}
		});
	})
	useEffect(() => {
		setInFocus(history.location.pathname.indexOf('nearby') != -1);
	}, [history.location.pathname])

	return (
		<IonPage>
			<GeoLocationContextProvider
				privacyPolicyURL={'/home/dashboard/account/privacy-policy'}
				locationRequired
				forceRefresh={forceLocationRefresh}
				onLocationReceived={() => setForceLocationRefresh(false)}
			>
				<GeoLocationContext.Consumer>
					{geoLocationContext =>
						<MeContextProvider>
							<MeContext.Consumer>
								{meContext =>
									<NearbyPostsContextProvider latitude={geoLocationContext.location.coords.latitude} longitude={geoLocationContext.location.coords.longitude}>
										<NearbyPostsContext.Consumer>
											{nearbyPostsContext =>
												<NewsFeed
													meContext={meContext}
													postsContext={nearbyPostsContext}
													selectLocation={locations => findClosestLocation(geoLocationContext.location.coords.latitude, geoLocationContext.location.coords.longitude, locations)}
													onPostSeen={nearbyPostsContext.removeModel}
													locationPageUrlRoot={'/home/dashboard'}
													userPageUrlRoot={'/home/dashboard'}
													inFocus={inFocus}
													onScroll={onScroll}
													noPosts={{
														copy: "There are no posts from people or places in your area at this time. Check back soon!",
														icon: '/assets/app-icons/browse.svg',
													}}
												/>
											}
										</NearbyPostsContext.Consumer>
									</NearbyPostsContextProvider>
								}
							</MeContext.Consumer>
						</MeContextProvider>
					}
				</GeoLocationContext.Consumer>
			</GeoLocationContextProvider>
		</IonPage>
	);
};

export default Nearby;
