import React from 'react'
import {IonContent, IonRouterLink, IonSpinner} from '@ionic/react';
import { Link } from 'react-router-dom';

interface PrivacyPolicyTextProps {
	contactUsUrl: string,
}

const PrivacyPolicyText: React.FC<PrivacyPolicyTextProps> = ({contactUsUrl}) => {
	return (
		<IonContent>
			<h3>Effective as of March 1, 2022</h3>
			<p>We at Geddit Local (“Geddit Local,” “we,” “us,” or “our”) have created this privacy policy (this “Privacy Policy”) because we know that you care about how information you provide to us is used and shared.  This Privacy Policy relates to the information collection and use practices of Geddit Local in connection with our online services and mobile applications (the “Service”), which is made available to you through a variety of platforms, such as through http://www.gedditlocal.com (the “Website”), through social media (collectively, “Social Media”), and through cell phones, tablets, and other mobile devices (collectively, the “Mobile Devices”).  The Website, our Social Media pages, and the Mobile Devices are collectively referred to as the “Platforms.”</p>
			<p>By visiting our Website and/or using our Service on any of the Platforms, you are agreeing to the terms of this Privacy Policy and the accompanying Terms of Use.    </p>
			<p>Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.</p>
			<h3>The Information We Collect</h3>
			<p>1.	<u>Personal Information</u></p>
			<p>When you sign up to become a Registered User or enter any promotion or sweepstakes, you will be required to provide us with personal information about yourself (collectively, the “Personal Information”).  Such Personal Information for Registered Users includes your name, e-mail address, date of birth, and phone number.  We do not collect any Personal Information from you when you use the Service unless you provide us with the Personal Information voluntarily.  Please note, we and/or our service providers will use your phone number only to: (i) verify your account, (ii) make signing into your account easier and more secure.</p>
			<p>2.	<u>Billing Information</u></p>
			<p>In order to create a Business Account, you will be required to provide certain information in addition to the Personal Information noted above.  Such information may include a debit card number, credit card number, billing address, and similar information (collectively, the “Billing Information).</p>
			<p>3.	<u>Geolocational Information</u></p>
			<p>In order to provide the Service while you are using a Mobile Device, we need to collect geolocational information from your Mobile Device, your wireless carrier, or certain third-party service providers (“Geolocational Information”).  Collection of such Geolocational Information occurs only when the Service is running on your Mobile Device.  You may decline to allow us to collect such Geolocational Information, in which case Geddit Local will not be able to provide its services to you.  Because your Geolocational Information is subject to abuse by others, please be sure to manage your Mobile Device and privacy preferences on the Service on an ongoing basis.</p>
			<p>4.	<u>Other Information</u></p>
			<p>In addition to the Personal Information noted above that you voluntarily provide to us and the Geolocational Information that we automatically collect, we may collect additional information (collectively, the “Other Information”).  Such Other Information may include:</p>
			<p>	a.	<u>From You</u>.  Additional information about yourself that you voluntarily provide to us, such as lifestyle interests, and product and service preferences.</p>
			<p>	b.	<u>From Your Activity</u>.  Information that we automatically collect when you use the Service, such as the type of Platform you’re using, your IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, what sections of the Application you visit, engagement with in-app content, and similar information concerning your use of the Service.   </p>
			<p>	c.	<u>From Cookies</u>.  Information that we collect using “cookie” technology.  Cookies are small packets of data that a website stores on your computer’s hard drive so that your computer will “remember” information about your visit.  We use cookies to help us collect Other Information and to enhance your experience using the Service.  If you do not want the Platforms to place a cookie on your hard drive, you may be able to turn that feature off on your computer or Mobile Device.  Please consult your Internet browser’s documentation for information on how to do this.  However, if you decide not to accept cookies from us, the Service may not function properly.  When the Service is made available to you through Facebook, Twitter, and/or other third-party Social Media sites, please be aware that these other sites maintain their own policies regarding cookies and the collection and use of information.  You are responsible for reviewing those policies.</p>
			<h3>The Information Collected by or Through Third-Party Advertising Companies</h3>
			<p>We may share Other Information about your activity on the Platforms with third parties for the purpose of tailoring, analyzing, managing, reporting, and optimizing advertising you see on the Platforms and elsewhere.  These third parties may use cookies, pixel tags (also called web beacons or clear gifs), and/or other technologies to collect such Other Information for such purposes.  Pixel tags enable us, and these third-party advertisers, to recognize a browser’s cookie when a browser visits the site on which the pixel tag is located in order to learn which advertisement brings a user to a given site.</p>
			<h3>Accessing and Modifying Personal Information and Communication Preferences</h3>
			<p>If you have registered for the Service, you may access, review, and make changes to your Personal Information, Billing Information, and Other Information by following the instructions found on the applicable Platform.  In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “unsubscribe” link located on the bottom of any Geddit Local marketing email, or disabling particular notifications in your Account Settings.  Registered Users cannot opt out of receiving transactional e-mails related to their account.  We will use commercially reasonable efforts to process such requests in a timely manner.  You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases.</p>
			<h3>How We Use and Share the Information  </h3>
			<p>We use the Personal Information, the Billing Information, the Geolocational Information, and the Other Information to provide you the Service, solicit your feedback, inform you about our products and services and those of our third-party marketing partners, and to improve our Service to you.  Also, we may share Personal Information, the Billing Information, Geolocational Information, and/or Other Information as described below.</p>
			<ul>
				<li>In an ongoing effort to better understand our users and our Service, we might analyze the Other Information and the Geolocational Information in aggregate form in order to operate, maintain, manage, and improve the Service.  This aggregate information does not identify you personally.  We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our products and Services to current and prospective business partners and to other third parties for other lawful purposes.</li>
				<li>We may employ other companies and individuals to perform functions on our behalf.  Examples may include providing marketing assistance, information technology support, and customer service.  These other companies will have access to the Personal Information, the Billing Information, the Geolocational Information, and the Other Information only as necessary to perform their functions and to the extent permitted by law. </li>
				<li>If we or all or substantially all of our assets are acquired, we expect that the Personal Information, the Billing Information, the Geolocational Information, and the Other Information would be transferred along with the other business assets. </li>
				<li>To the extent permitted by law, we may also disclose Personal Information, the Billing Information, the Geolocational Information, and the Other Information when required by law, court order, or other government or law enforcement authority or regulatory agency, or whenever we believe that disclosing the such Information is necessary or advisable, for example, to protect the rights, property, or safety of Small Shops United or others.  </li>
			</ul>
			<h3>How We Protect Your Information</h3>
			<p>We take commercially reasonable steps to protect the Personal Information, the Geolocational Information, and the Other Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.  We use industry-standard SSL (“Secure Socket Layer”) encryption technology, a secure server, and other physical and procedural safeguards to protect the security of such information.  Please understand, however, that no security system is impenetrable.  We cannot guarantee the security of our databases, nor can we guarantee that the information you supply will not be intercepted while being transmitted to and from us over the Internet. In particular, e-mail sent to or from the Platforms may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.</p>
			<h3>Important Notice to Non-U.S. Residents</h3>
			<p>The Website and the Service are operated in the United States.  If you are located outside of the United States, please be aware that any information you provide to us will be transferred to the United States.  By using the Service and/or providing us with any information, you consent to this transfer.  </p>
			<h3>Children </h3>
			<p>We do not knowingly collect Personal Information from children under the age of 17 through the Service.  If you are under 17, please do not give us any Personal Information.  We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children to never provide Personal Information through the Service without their permission.  If you have reason to believe that a child under the age of 17 has provided Personal Information to us, please contact us, and we will endeavor to delete that information from our databases.</p>
			<h3>California Residents</h3>
			<p>Under California Civil Code Section 1798.83, California residents who have an established business relationship with Geddit Local may choose to opt out of our sharing your Personal Information with third parties for direct marketing purposes.  If you are a California resident and (1) you wish to opt out; or (2) you wish to request certain information regarding our disclosure of your Personal Information to third parties for the direct marketing purposes, please send an e-mail to privacy@smallshopsunited.com or write to us at:</p>
			<p><strong>
				Geddit Local
				<br/>
				224 W. Vine St.
				<br/>
				Unit 202
				<br/>
				Milwaukee, WI 53212
			</strong></p>
			<h3>External Websites</h3>
			<p>The Website and the Service may contain links to third-party websites.  Geddit Local has no control over the privacy practices or the content of any of our business partners, advertisers, sponsors, or other websites to which we provide links.  As such, we are not responsible for the content or the privacy policies of those third-party websites.  You should check the applicable third-party privacy policy and terms of use when visiting any other websites. </p>
			<h3>Changes to This Privacy Policy</h3>
			<p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy.  We may change this Privacy Policy from time to time, and will post any changes on the Website as soon as they go into effect.  By accessing the Website or using the Service after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes.  Please refer back to this Privacy Policy on a regular basis. </p>
			<h3>How to Contact Us</h3>
			<p>If you have questions about this Privacy Policy, please e-mail us at support@gedditlocal.com.</p>
		</IonContent>
	)
}

export default PrivacyPolicyText
